@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

#teamDetail {
    isolation: isolate;

    #bannerHero .text-container {
        max-width: 100%;
    }

    #bannerHero .text-container .title {
        text-align: center;
        margin: 50px 0 50px 33px;
        max-width: 100%;

        @media(max-width: 991px) {
            text-align: left;
            margin: 50px 0 50px 0;
        }
    }

    #bannerHero .breadcumbs {
        padding-bottom: 34px;
        margin-left: 13px;
    }

    #bannerHero .socials {
        position: absolute;
        left: 128px;
        top: 187px;
        gap: 20px !important;
        width: min-content;


        @media(max-width: 991px) {
            right: inherit;
            left: 24px;
        }
    }

    .biografia {
        padding: 120px 0 106px 0;

        @media(max-width: 991px) {
            padding: 120px 0 50px 0;
        }

        .perfil {
            margin-left: 13px;

            @media(max-width: 991px) {
                margin-left: 0;
            }
        }

        .imagem {
            width: 540px;
            height: 560px;

            @media(max-width: 991px) {
                width: 100%;
                height: 415px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .dados {
            max-width: 540px;
            padding: 38px 20px;
            background-color: #1B505308;

            @media(max-width: 991px) {
                padding: 20px;
                margin-bottom: 60px;
            }

            .nome {
                font-family: 'Bicyclette';
                font-size: 45px;
                font-weight: 700;
                line-height: 40px;
                letter-spacing: 0em;
                color: #172223;
                margin-bottom: 31px;

                @media(max-width: 991px) {
                    font-size: 30px;
                    margin-bottom: 15px;
                }
            }

            p {
                font-family: 'Bicyclette';
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
                color: #7D7D7D;
                max-width: 395px;
            }

            .redes {
                display: flex;
                margin-top: 43px;
                gap: 31px;

                a {
                    text-decoration: none;
                }

                svg {
                    max-width: 28px;
                    max-height: 28px;
                    transition: all .3s ease;

                    &:hover {
                        scale: 1.1;
                    }
                }
            }
        }

        .biografia-desc {
            margin-left: 14px;

            p {
                font-family: 'Bicyclette';
                font-size: 18px;
                font-weight: 400;
                line-height: 25px;
                letter-spacing: 0em;
                text-align: left;
                color: #7D7D7D;
                margin-bottom: 25px;
                max-width: 618px;

                @media(max-width: 991px) {
                    font-size: 16px;
                    margin-bottom: 20px;
                }
            }

            a {
                font-family: 'Bicyclette';
                font-size: 18px;
                font-weight: 400;
                line-height: 25px;
                letter-spacing: 0em;
                text-align: left;
                color: #1B5053;
            }
        }

        .title {
            padding-top: 39px;
            margin-left: 14px;
            margin-bottom: 33px;
        }
    }

    .linha-tempo {
        padding-bottom: 86px;

        @media(max-width: 991px) {
            padding-bottom: 0px;
        }

        .title {
            padding-top: 39px;
            margin-bottom: 38px;
            margin-left: 14px;
        }

        p {
            font-family: 'Bicyclette';
            font-size: 18px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;
            color: #7D7D7D;
            max-width: 473px;
            margin-left: 14px;

            @media(max-width: 991px) {
                font-size: 16px;
                margin-bottom: 60px;
            }
        }

        .pontos {
            display: flex;
            gap: 59px;

            &:hover {
                .bullet { scale: 1.2; }
                .item {
                    h3 { color: #C47D53; }
                    p { translate: 10px 0; }
                }
            }

            .bullet {
                margin-left: 14px;
                margin-top: 24px;
                position: relative;
                transition: all .5s ease;

                &::after {
                    content: '';
                    position: absolute;
                    left: 7px;
                    bottom: -24px;
                    width: 6px;
                    height: calc(100% + 24px);
                    background-color: #1B50531A;
                    z-index: -1;
                }

            }

            &:last-child .bullet::after {
                display: none;
            }

            .item {
                margin-bottom: 40px;

                h3 {
                    font-family: 'Bicyclette';
                    font-size: 25px;
                    font-weight: 700;
                    line-height: 25px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #1B5053;
                    margin-bottom: 19px;
                    transition: all .5s ease;

                    @media(max-width: 991px) {
                        font-size: 22px;
                    }
                }

                p {
                    font-family: 'Bicyclette';
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #7D7D7D;
                    max-width: 339px;
                    margin-left: 0;
                    transition: all .5s ease;

                    @media(max-width: 991px) {
                        font-size: 16px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    .conteudo {
        padding-block: 161px 131px;
        position: relative;
        z-index: -2;

        @media (max-width: 1100px) {
            padding-block: 60px 72px;

            & > .container {
                padding-inline: 25px;
            }
        }
        .title {
            max-width: fit-content;
            margin-inline: auto;

            &::after {
                left: 40%;

                @media (max-width: 1100px) {
                    left: 33%;
                }
            }
        }
        .text {
            margin-block: 34px 70px;

            @media (max-width: 1100px) {
                margin-block: 15px 36.9px;
                max-width: 302px;
                margin-inline: auto;
            }
        }
        .title,
        .text {
            text-align: center;
        }
        .container > .row > .col-lg-4 {
            display: flex;
            flex-direction: column;
            gap: 32px;
        }
        &-title {
            color: #28464a;
            font-size: 30px;
            font-weight: 700;
            line-height: 40px;

            @media (max-width: 992px) {
                text-align: center;
                margin-bottom: 0;
            }
        }
        &-sm {
            display: flex;
            gap: 21px;

            &:hover {
                .conteudo-sm-img { scale: 1.1; }
            }
            .img-wrapper {
                min-width: 138px;
                min-height: 98px;
                max-width: 138px;
                max-height: 98px;
                overflow: hidden;
            }
            &-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all .3s ease;
            }
            &-text {
                color: #28464a;
                font-size: 17px;
                font-weight: 700;
                line-height: 16px;
                margin-bottom: 10px;
            }
            .date {
                height: 23px;
                width: 98px;
                background-color: #c47d53;

                display: grid;
                place-items: center;

                color: #fff;
                font-size: 15px;
                font-weight: 400;
                line-height: 25px;
            }
        }
        .btn-wrapper {
            display: none;

            @media (max-width: 992px) {
                display: flex !important;
                justify-content: center;
            }
        }
        @media (max-width: 992px) {
            .col-lg-4 + .col-lg-4 {
                margin-top: -40px;
            }
            .col-12 {
                display: none;
            }
        }
    }

    .conteudos-team {
        padding-top: 122px;
        padding-bottom: 154px;
        background-color: #1B50530D;

        @media(max-width: 991px) {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        .title {
            text-align: center;
            margin-bottom: 33px;

            &::after {
                right: 0;
                margin: 0 auto;
            }
        }

        p {
            font-family: 'Bicyclette';
            font-size: 18px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: center;
            color: #7D7D7D;
        }

        .posts {
            margin-top: 71px;

            .post {
                position: relative;
                height: 322.14px;
                overflow: hidden;

                &:hover {
                    & > img {
                        scale: 1.1;
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: linear-gradient(0deg, #28464A 26.14%, rgba(40, 70, 74, 0) 75.84%);
                    z-index: 1;
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 3.9px;
                    bottom: 0;
                    left: 0;
                    background: #C47D53;
                    z-index: 2;
                }

                & > img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    z-index: 1;
                    transition: all .3s ease;
                }

                .data {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 25px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #fff;
                    padding: 1px 12px;
                    background-color: #C47D53;
                    position: absolute;
                    top: 22px;
                    left: 46px;
                    z-index: 2;

                    @media(max-width: 991px) {
                        left: 30px;
                    }
                }

                .conteudo-body {
                    position: absolute;
                    bottom: 29px;
                    left: 43px;
                    z-index: 2;
                    max-width: 339px;

                    @media(max-width: 991px) {
                        left: 30px;
                    }

                    h3 {
                        font-family: Bicyclette;
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 22px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #fff;
                        margin-bottom: 20px;
                    }

                    .autor {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        font-family: Bicyclette;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 25px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #FFFFFF80;
                    }
                }
            }

            .slick-dots li button {
                width: 13.96px;
                height: 13.96px;
            }

            .slick-dots li button:before {
                width: 13.96px;
                height: 13.96px;
                color: transparent;
                border: 1px solid #7D7D7D;
                opacity: 1;
            }

            .slick-dots li.slick-active button:before {
                background-color: #C47D53;
                border-color: #C47D53;
            }

            .slick-dots {
                bottom: -64px;
            }

            .slick-dots li {
                margin: 0 3px;
            }

            .slick-slide .post {
                margin-left: 7.5px;
                margin-right: 7.5px;
            }

            .slick-list {
                margin-left: -7.5px;
                margin-right: -7.5px;
            }

        }
    }
}
