.button {
    display: flex;
    align-items: center;
    gap: 16.8px;
    width: fit-content;

    font-family: inherit;
    font-size: 22px;
    font-weight: 700;
    line-height: 50px;
    text-decoration: none;
    transition: all .3s ease;

    .btn-icon { transition: all .3s ease-out; }
    &:hover { 
        color: #C47D53;

        .btn-icon { translate: 2px 0;  }
    }
}
.btn-white {
    color: #fff;

    .btn-icon {
        filter: brightness(0) invert(1);
    }
}
.btn-dark { color: #1B5053; }