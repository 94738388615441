#atuation {
    isolation: isolate;

    .atuation {
        padding-block: 120px 0;
        text-align: center;
        max-height: auto;

        @media (max-width: 1100px) {
            padding-block: 60px 0;
            max-height: 100%;

            .container {
                padding-inline: 25px;
            }
        }
        &--text {
            .title {
                width: fit-content;
                margin-inline: auto;

                &::after {
                    left: 40%;
                }
            }
            .text {
                color: #7D7D7D;
                max-width: 566px;
                margin-inline: auto;
                margin-block: 32px 70px;

                @media (max-width: 1100px) {
                    max-width: 340px;
                    margin-block: 34px 50px;
                }
            }
        }
        &--grid {
            margin-bottom: -100px;

            &-item {
                padding: 39.7px 47.2px;
                background-color: #F4F6F6;
                border-bottom: 3px solid #C47D53;
                min-height: 419px;

                @media (max-width: 1100px) {
                    padding: 39.7px 12px 51.78px;
                    min-height: auto;
                }
                .grid-icon {
                    display: block;
                    margin-inline: auto;
                    width: 48px;
                    height: 48px;

                    @media (max-width: 1100px) {
                        width: 40px;
                        height: 40px;
                    }
                }
                .grid-title {
                    color: #172223;
                    font-size: 25px;
                    font-weight: 400;
                    line-height: 25px;
                    margin-block: 20px;
                    min-height: 50px;

                    @media (max-width: 1100px) {
                        margin-block: 20px 12px;
                    }
                }
                .grid-text {
                    color: #7D7D7D;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 22px;
                    margin-bottom: 14px;

                    -webkit-line-clamp: 2;
                    line-clamp: 2;

                    @media (max-width: 1100px) {
                        font-size: 16px;
                        line-height: 22px;
                        margin-bottom: 12px;
                    }
                }
                .button {
                    margin-inline: auto;
                    text-align: center;
                    justify-content: center;

                    font-size: 18px;
                    line-height: 30px;
                }
            }
            .col-lg-4 { padding-inline: 4.6px; }
        }        
    }
    .partners {
        @media (max-width: 1100px) {
            padding: 209px 0 134px;
            margin-top: calc(80px - 209px);
        }
        .bg-img { z-index: -3; }
        &::before {
            content: '';
            position: absolute;
            inset: 0;
            background-color: #28464A;
            mix-blend-mode: multiply;
            opacity: 0.8;
            z-index: -2;
        }
        &::after { z-index: -1; }
    }
    .testimonials {
        margin: 219px 184px 0 0;
        height: 861px;
        position: relative;
        isolation: isolate;
        background-color: #fff;

        @media (max-width: 1500px) {
            margin-right: 80px;
        }
        @media (max-width: 1100px) {
            margin: 0;
            height: 976px;

            display: flex;
            align-items: end;

            .bg-img {
                height: 641px;
            }
        }
        &::after {
            content: '';
            position: absolute;
            inset: 0;
            background: linear-gradient(90deg, #172223 4.3%, rgba(23, 34, 35, 0) 88.56%);
            mix-blend-mode: multiply;
            z-index: -1;

            @media (max-width: 1100px) {
                display: none;
            }
        }
        &-floating {
            position: absolute;
            top: 90px;
            right: -1px;
            z-index: 1;

            background-color: #fff;
            padding: 146px 0 156px 144px;
            width: Min(calc(1166px - 184px), 75vw);

            @media (max-width: 1100px) {
                margin-left: 25px;
                width: calc(100% - 25px); // minus margin-left value
                padding: 60px 35px 105px 43px;
                position: unset;
            }
            .text {
                color: #7D7D7D;
                margin-block: 60px 20px;
                max-width: 565px;

                @media (max-width: 1100px) {
                    margin-block: 44px 20px;
                }
                strong {
                    color: #1B5053;
                    font-weight: 700;
                }
            }
            .name {
                color: #C47D53;
                font-size: 22px;
                font-weight: 700;
                line-height: 50px;
            }
            .quotation {
                position: absolute;
                right: 158px;
                bottom: 156px;
                z-index: 2;

                @media (max-width: 1100px) {
                    width: 79.57px;
                    height: 66px;
                    right: 7px;
                    bottom: 130px;
                }
            }
        }
        .swiper {
            @media (max-width: 1100px) {
                &, &-slide {
                    max-height: 677px;
                }
            }
            &-slide { 
                position: relative;
                height: 100%;
                width: 100%;

                @media (max-width: 1100px) {
                    .bg-img { 
                        position: relative;
                        height: 641px;
                    }
                }
            }
            &-pagination {
                inset: auto 122px 60px auto;
                z-index: 2;

                @media (max-width: 1100px) {
                    inset: auto auto 59.9px 65px;
                    text-align: start;
                }
                &-bullet {
                    border: 1px solid #28464A;

                    &:not(&-active) { background: #fff; }
                }    
            }
        }
    }
}

#bannerHero {
    position: relative;
    margin-top: 106px; //navbar height
    padding-top: 160px;
    height: Min(calc(100vh - 106px), 521px);
    isolation: isolate;

    @media (max-width: 1100px) {
        margin-top: 90px;
        padding-top: 69.7px;
        min-height: 430.7px;
    }
    &::after, &::before {
        content: '';
        position: absolute;
        inset: 0;
        mix-blend-mode: multiply;
        z-index: -1;
    }
    &::after {
        background: linear-gradient(90deg, rgba(27, 80, 83, 0.8) 3.28%, rgba(27, 80, 83, 0.8) 51.12%, rgba(27, 80, 83, 0) 84.69%);
    }
    &::before {
        background: linear-gradient(90deg, #1B5053 21.73%, rgba(27, 80, 83, 0) 54.81%);        
    }
    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        @media (max-width: 1100px) { 
            margin-inline: 0;
            padding-inline: 25px;
        }
    }
    .text-container {
        max-width: 740px;
        
        @media (max-width: 1100px) {
            max-width: 100%;
        }
        .title {
            font-size: 60px;
            font-weight: 700;
            line-height: 50px;
            padding-top: 0;
            margin-bottom: 29px;
            max-width: 532.6px;

            @media (max-width: 1100px) {
                font-size: 35px;
                line-height: 1;
                margin-bottom: 12px;
            }
            &::after { display: none; }
        }
        .text { color: #fff; }
    }
    .breadcumbs {
        padding-bottom: 20px;

        ul {
            &, li > a {
                display: flex;
                align-items: center;
                gap: 20px;

                @media (max-width: 1100px) { gap: 12px; }
            }
            li {
                color: #fff;
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;
                transition: all .3s ease-in-out;

                @media (max-width: 1100px) {
                    font-size: 14px;
                    line-height: 22px;
                }
                a { text-decoration: none; }
                &:not(:last-child) { opacity: .7; }
                &:hover { opacity: 1; }
            }
        }
    }
    .socials {
        position: absolute;
        left: 138px;
        top: 187px;

        @media (max-width: 1700px) {
            left: 28px;
        }
        @media (max-width: 1480px) {
            left: auto;
            right: 60px;
        }
        &:nth-of-type(odd) { // mobile
            display: none !important;

            @media (max-width: 1100px) {
                display: flex !important;
                flex-direction: row !important;
                position: unset;

                margin-block: 20px;
            }
        }
        &:nth-of-type(even) { // desktop
            display: flex !important;

            @media (max-width: 1100px) {
                display: none !important;
            }
        }
    }
    .gap-3 { gap: 24px !important; }
}