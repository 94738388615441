/*------------------------- Containers -----------------------*/

.termsfeed-com---nb .cc-nb-main-container{

}

.termsfeed-com---palette-dark.termsfeed-com---nb{
    background: linear-gradient(111.77deg, #1b5053 2.4%, rgba(27, 80, 83, 0) 93.16%);
    background-color: #172223;
}
.termsfeed-com---palette-light.termsfeed-com---nb{
    box-shadow: 0px 3px 10px #172223;
}
.termsfeed-com---palette-light.termsfeed-com---nb{
    background-color: white;
}
/*-------------------------TEXTS -----------------------*/


/*------------------------ BUTTONS -----------------------*/
.termsfeed-com---palette-dark .cc-nb-okagree{
    background: linear-gradient(141deg, #da8858 31.89%, transparent) rgba(86, 58, 42, 0.9215686275) 34%;
    color: #fff;
    margin-right: 1rem !important;
    transition: background-color 1s;
    &:hover{
        background-color: #da8858;
    }
}
.termsfeed-com---palette-dark .cc-nb-reject{
    color: #fff;
    background: linear-gradient(141deg, #da8858 31.89%, transparent) rgba(86, 58, 42, 0.9215686275) 34%;
    margin-right: 1rem !important;
    transition: background-color 1s;
    &:hover{
        background-color: #da8858;
    }
}

.termsfeed-com---nb-simple{
    left: inherit;
    right: 20vw;
    bottom: 2vh;
    border: none;
    transition: 0.4s;
    &::after{
    content: "";
    width: 100%;
    height: 3px;
    background-color: #da8858;
    position: absolute;
    left: 0;
    bottom: 0;
    }
}
// Accept
.termsfeed-com---palette-light .cc-nb-okagree{
    border-radius: 0px !important;
    padding: 10px 20px !important;
}
// Decline
.termsfeed-com---palette-light .cc-nb-reject{
    border-radius: 0px !important;
    padding: 10px 20px !important;
}
// Config
.termsfeed-com---palette-light .cc-nb-changep{
    border-radius: 0px !important;
    padding: 10px 20px !important;
}
