.navbar {
    background-color: #fff;

    @media (max-width: 1100px) {
        padding-block: 8px;

        .container { padding-inline: 25px; }
        &-brand {
            img {
                max-width: 132px;
                max-height: 74.25px;
            }
        }
    }
    &-toggler {
        width: 71px;
        height: 70px;
        background: linear-gradient(
            141deg,
            #da8858 31.89%,
            #563a2a 100%
        );
        border: none;
        border-radius: 0;

        display: grid;
        place-items: center;

        @media (max-width: 1100px) {
            width: 52px;
            height: 51px;
        }
        &:focus {
            box-shadow: none;
        }
    }
    &-collapse {
        .nav-link {
            color: #7D7D7D;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            padding: 8px;
            transition: all .3s ease;

            &:hover { 
                color: #C47D53;

                .bi { color: inherit; }
            }
            .active {
                color: #C47D53;
                font-weight: 700;
            }
            &.show { 
                color: #C47D53;
            }
        }
    }
    &-nav {
        gap: 20px;

        @media (max-width: 1290px) {
            gap: 0;
        }
        .dropdown {
            &-toggle {
                &::after {
                    display: none;
                }
                &[aria-expanded="true"] {
                    .bi::before {
                        transform: rotate(-180deg);
                    }
                }
                .bi {
                    font-size: 13px;
                    margin-left: .25rem;

                    &::before {
                        transition: all .3s ease-out;
                    }
                }
            }
            &-menu {
                border: none;
                border-radius: 0;
                padding: 32px 20px 8px;

                top: 100%;
                left: -28px;

                box-shadow: 7px -2px 26px -10px rgba(0,0,0,0.2);                
                -webkit-box-shadow: 7px -2px 26px -10px rgba(0,0,0,0.2);
                -moz-box-shadow: 7px -2px 26px -10px rgba(0,0,0,0.2);

                &[data-dropdown="contact"] {
                    @media(max-width: 1840px) {
                        left: auto;
                        right: 0;
                    }
                }
            }
            &-item {
                color: #7D7D7D;
                font-size: 20px;
                font-weight: 400;
                line-height: 30px;
                padding-block: 12px;
                transition: all .3s ease;

                &:hover { 
                    color: #C47D53;
                    background-color: inherit;
                }
            }
        }
    }
    .nav-link, .dropdown-item {
        &.active {
            color: #C47D53;
            font-weight: 700;
            background-color: inherit;
        }
    }
    // navbar collapse custom breakpoint
    @media (min-width: 1100px){ // collapse at 1100px
        &-expand-custom {
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
        }
        &-expand-custom &-nav {
            flex-direction: row;
        }
        &-expand-custom .dropdown-menu {
            position: absolute;
        }
        &-expand-custom .nav-link {
            padding-right: .5rem;
            padding-left: .5rem;
        }
        &-expand-custom > .container {
            flex-wrap: nowrap;
        }
        &-expand-custom &-collapse {
            display: flex!important;
            flex-basis: auto;
        }
        &-expand-custom &-toggler {
            display: none;
        }
    }    
}