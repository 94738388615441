#page404{
  display: grid;
  place-items: center;

  position: relative;
  margin-top: 106px; // navbar height
  min-height: calc(100vh - 106px);
  isolation: isolate;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: 
      linear-gradient(350.13deg, rgba(27, 80, 83, 0.7) 21.73%, rgba(27, 80, 83, 0) 54.81%),
      linear-gradient(350.13deg, #1B5053 21.73%, rgba(27, 80, 83, 0) 54.81%),
      #1B5053CC;
    mix-blend-mode: multiply;
    z-index: -1;
  }
  .container {
    padding-inline: 25px;
  }
  .wrapper {
    max-width: 500px;
    margin-inline: auto;
    text-align: center;
    color: #fff;
    margin-block: 40px;

    h1 {
      color: #C47D53;
      font-size: 278px;
      font-weight: 700;
      line-height: 1;

      @media (max-width: 1100px) {
        font-size: 110.71px;
      }
    }
    h2 {
      font-size: 45px;
      font-weight: 700;
      line-height: 40px;
      margin-block: 40px 32px;

      @media (max-width: 1100px) {
        font-size: 35px;
        line-height: 1;
      }
    }
    .text {
      max-width: 462px;
      margin-inline: auto;
      line-height: 22px;
      opacity: .8;
    }
    .btn-wrapper {
      margin-top: 60px;
    }
  }
}