#eventDetail {
    display: grid; 
    // faz o container do #bannerPosts pegar a altura de 100% por algum motivo

    .wrapper {
        padding: 120px 110px;

        @media (max-width: 1500px) {
            padding-inline: 70px;
        }
        @media (max-width: 1100px) {
            padding: 60px 25px;
        }
        .container {
            padding-inline: 0;
            margin-right: 0;

            @media (min-width: 1400px) {
                max-width: 1506px !important;
            }
            @media (max-width: 1500px) {
                margin-inline: auto;
            }
            @media (max-width: 1100px) {
                max-width: 100%;
            }
        }
        .palestrante {
            margin-bottom: 120px;

            @media (max-width: 1100px) {
                margin-bottom: 60px;

                .row {
                    gap: 60px;
                }
            }
            &--text {
                max-width: 475px;

                @media (max-width: 1100px) {
                    max-width: 100%;
                }
                .title { 
                    margin-bottom: 60px;

                    @media (max-width: 1100px) {
                        margin-bottom: 32px;
                    }
                }
                .text { color: #7D7D7D; }
                .text + .text { margin-top: 20px; }
            }
            &--img {
                height: 720px;

                @media (max-width: 1100px) {
                    height: 455px;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .information {
            @media (max-width: 1100px) {
                .row { gap: 60px; }
            }
            &--cards {
                display: flex;

                & > .row { 
                    justify-content: center;
                    width: 100%;
                }
                .card-info {
                    text-align: center;
                    height: 255px;
                    width: 100%;
                    border-bottom: 3px solid #C47D53;
                    background-color: #F4F6F6;
                    transition: all .5s ease;

                    padding: 38px 23px 37.5px 24px;

                    @media (max-width: 1400px) {
                        height: 280px;
                    }
                    @media (max-width: 1100px) {
                        height: 259px;
                        padding: 47px 23px 50px 24px;
                    }
                    &:hover {
                        translate: 0 -5px;
                    }
                    h3 {
                        color: #1B5053;
                        font-size: 25px;
                        font-weight: 400;
                        line-height: 25px;
                        margin-block: 20px;
                    }
                    img {
                        width: 50px;
                        height: 50px;
                        margin-inline: auto;

                        @media (max-width: 1100px) {
                            width: 40px;
                            height: 40px;
                        }
                    }
                    .text {
                        line-height: 22px;
                    }
                    h6 {
                        color: #7D7D7D;
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 50px;                      
                    }
                }
            }
            .text { color: #7D7D7D; }
        }
        .information-txt {
            max-width: 428px;
            margin-top: 67px;

            @media (max-width: 1100px) {
                max-width: 100%;
            }
            .text {
                margin-block-start: 40px;

                @media (max-width: 1100px) {
                    margin-block-start: 32px;
                }
            }
            .button { margin-block-start: 60px; }
        }
    }
    .join {
        margin: 0 184px 0 0;

        .socials {
            margin-top: 60px;
            gap: 24px !important;

            img {
                width: 32px;
                height: 32px;
                filter: invert(56%) sepia(17%) saturate(2211%) hue-rotate(338deg) brightness(96%) contrast(87%);
                transition: all .3s ease;

                &:hover {
                    filter: brightness(1) invert(1);
                    opacity: .7;
                }
            }
        }
    }
}