#intro {
    background-color: black;
    color: white;
    height: 100vh;
    position: relative;
    width: 100%;
    overflow: hidden;
    isolation: isolate;

    @media (max-width: 1100px) {
        & > .container { padding-inline: 25px; }
    }
    .container {
        position: relative;
    }
    .container-text {
        position: relative;
        bottom: -30vh;

        @media (max-width: 1100px) {
            bottom: auto;
            top: Min(125px, 13vh);
        }
    }
    .logo {
        padding-top: 54px;

        @media (max-width: 1100px) {
            padding-top: 16px;

            img {
                width: 128px;
                height: 72px;
            }
        }
    }
    .viewport-image {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -2;
        animation: sliderAnimation 15s ease infinite alternate;
    }


    #logoSvg {
        height: 90vh;
        position: absolute;
        right: 19.375vw;
        top: 43px;
        z-index: -1;

        @media (max-width: 1100px) {
            max-height: 324px;
            top: auto;
            bottom: 0;
            right: auto;
            left: -234px;
        }
    }
    .line {
        width: 70%;
        height: 70px;
        position: relative;
        overflow: hidden;
        span {
            position: absolute;
            font-size: 70px;
            line-height: 70px;
        }
        u {
            text-decoration: none;
            color: #c47d53;
        }
    }
    .floating-text {
        position: absolute;
        bottom: 69px;
        right: 103px;
        writing-mode: vertical-rl;

        @media (max-width: 1100px) {
            display: none !important;
        }
        svg {
            cursor: pointer;
        }
        p {
            color: #fff;
            font-size: 22px;
            font-weight: 400;
            line-height: 50px;
        }
    }
}
#menu {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    left: 0;
    overflow: hidden;
    visibility: hidden;

    .btnClose{
        button{
            width: 71px;
            height: 70px;
            background: linear-gradient(141deg, #da8858 31.89%, transparent) rgba(86, 58, 42, 0.9215686275) 34%;
            transition: background-color 1s;
            display: grid;
            place-items: center;

            @media (max-width: 1100px) {
                width: 52px;
                height: 51px;
            }
            &:hover{
                background-color: #da8858;
            }
        }
    }
    .menu-itens {
        padding-left: 112px;

        @media (max-width: 1100px) {
            padding-left: 73px;
        }
        @media (max-width: 500px) {
            padding-left: 25px;
        }
        ul {
            li {
                color: #fff;
                margin-top: 27px;
                font-size: 30px;
                font-weight: bold;
                position: relative;
                transition: color .3s ease-in-out;

                @media (max-width: 1100px) {
                    font-size: 18px;
                    line-height: 36px;
                    margin-top: 0 !important;
                }
                @media (max-height: 850px) {
                    margin-top: 1vh;
                }
                @media (max-height: 670px) {
                    margin-top: 0;
                }
                &:hover {
                    color: #C47D53;
                }
            }
            .active {
                color: #c47d53;

                &::after {
                    content: "";
                    position: absolute;
                    top: -27px;
                    left: 0;
                    width: 66px;
                    height: 6px;
                    background: #c47d53;

                    @media (max-width: 1100px) {
                        top: -12px !important;
                        height: 4px;
                        width: 40px;
                    }
                    @media (max-height: 670px) {
                        top: -15px;
                    }
                }
            }
        }
    }
    .logo-svg {
        height: 65vh;
        position: absolute;
        right: 327px;
        bottom: 118px;
    }
    .bg-menu {
        position: absolute;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            animation: sliderAnimation 15s ease infinite alternate;
        }
    }
    .navbar {
        padding-block: 24px;
        background: transparent;

        @media (max-width: 1100px) {
            padding-block-start: 16px !important;

            .logo img {
                width: 128px;
                height: 72px;
            }
        }
        @media (max-height: 670px) {
            padding-block: 24px 10px;
        }
        .container {
            padding-inline: 0;

            @media (max-width: 1100px) {
                padding-inline: 25px;
            }
        }
        .content {
            gap: 56px;
        }
        .socials { 
            gap: 24px;

            @media (max-width: 1100px) {
                display: none !important;
            }
        }
    }
}

#home {
    isolation: isolate;

    .banner-home {
        position: relative;
        height: Min(103vh, 924px);
        isolation: isolate;

        @media (max-width: 1100px) {
            height: Min(685.39px, 100vh);

            .container { padding-inline: 25px; }
        }
        &::after {
            content: "";
            position: absolute;
            inset: 0;
            background:
                linear-gradient(90deg, rgba(1, 1, 1, 0.7) 21.26%, rgba(27, 80, 83, 0) 47.22%), 
                linear-gradient(90deg, rgba(27, 80, 83, 0.7) 33.26%, rgba(27, 80, 83, 0) 67.22%), 
                linear-gradient(90deg, #1B5053 24.94%, rgba(27, 80, 83, 0) 45.25%), 
                linear-gradient(90deg, rgba(27, 80, 83, 0.8) 13.28%, rgba(27, 80, 83, 0.8) 38%, rgba(27, 80, 83, 0) 50%);
            mix-blend-mode: multiply;
            z-index: -1;
        }
        .navbar {
            position: absolute;
            inset: 0 0 auto 0;
            width: 100%;
            padding-block: 24px;
            background: transparent;
            z-index: 3;

            @media (max-height: 710px) {
                padding-block: 0;
            }
            @media (max-width: 1100px) {
                padding-block: 37px 0 !important;

                .logo img {
                    width: 132.5px;
                    height: 74.53px;
                }
                .container { padding-inline: 25px; }
            }
            .content {
                gap: 56px;

                @media (max-width: 1100px) {
                    .socials { display: none !important; }
                }
                #hamburger {
                        width: 71px;
                        height: 70px;
                        background: linear-gradient(141deg, #da8858 31.89%, transparent) #563a2aeb 34%;
                        transition: background-color 1s;
                        &:hover{
                            background-color: #da8858;
                        }
                        display: grid;
                        place-items: center;

                        @media (max-width: 1100px) {
                            width: 52px;
                            height: 51px;
                        }
                }
                .gap-3 { gap: 24px !important; }
            }
        }
        .main {
            max-width: 505px;
            margin-top: Min(21vh, 215px);
            position: relative;
            z-index: 5;

            @media (max-height: 670px) {
                max-width: 600px;
            }
            @media (max-width: 1100px) {
                max-width: 300px;
                margin-top: 159.4px !important;
            }
            &-title {
                color: #fff;
                font-size: 55px;
                font-weight: 700;
                line-height: 50px;

                @media (max-width: 1100px) {
                    font-size: 35px;
                    line-height: 1;
                }
                strong {
                    color: #c47d53;
                    font-size: inherit;
                    line-height: inherit;
                }
            }
            &-text {
                color: #fff;
                font-size: 18px;
                font-weight: 400;
                line-height: 25px;
                margin-block: 32px 9.5vh;
                max-width: 505px;

                @media (max-height: 700px) {
                    margin-block: 20px;
                }
                @media (max-width: 1100px) {
                    margin-block: 25px 40px;
                    max-width: 257px;
                }
            }
        }
        .swiper {
            &-slide {
                width: 100%;
                height: 100%;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    inset: 0;
                    background:
                        linear-gradient(90deg, rgba(1, 1, 1, 0.7) 21.26%, rgba(27, 80, 83, 0) 47.22%), 
                        linear-gradient(90deg, rgba(27, 80, 83, 0.7) 33.26%, rgba(27, 80, 83, 0) 67.22%), 
                        linear-gradient(90deg, #1B5053 24.94%, rgba(27, 80, 83, 0) 45.25%), 
                        linear-gradient(90deg, rgba(27, 80, 83, 0.8) 13.28%, rgba(27, 80, 83, 0.8) 38%, rgba(27, 80, 83, 0) 50%);
                    mix-blend-mode: multiply;
                    opacity: .95;
                    z-index: 2;
                }
                .bg-img { z-index: -1; }
            }
            &-pagination {
                left: 147.9px;
                bottom: 242px;
                top: auto;
                z-index: 3;

                @media (max-width: 1700px) {
                    left: 25px;
                }
                @media (max-width: 1100px) {
                    text-align: center;
                    width: 100%;
                    left: 0;
                    bottom: 132px;
                }
            }
        }
        .floating-text {
            position: absolute;
            bottom: 69px;
            right: 103px;
            writing-mode: vertical-rl;
            z-index: 3;

            @media (max-width: 1100px) {
                display: none !important;
            }
            p {
                color: #fff;
                font-size: 22px;
                font-weight: 400;
                line-height: 50px;
            }
        }
    }
    .cards {
        position: relative;
        z-index: 2;
        margin-top: -105px;
        height: fit-content;

        @media (max-height: 590px) {
            margin-top: -20px;
        }
        @media (max-height: 820px) {
            margin-top: -50px;
        }
        @media (max-width: 1100px) {
            margin-top: -91px !important;

            & > .container {
                margin-inline: 0;
                padding-inline: 25px;
                max-width: 100%;
            }
        }
        .swiper {
            &-slide {
                max-width: 428.3px;

                @media (max-width: 1100px) {
                    max-width: 339px;
                }
            }
        }
        .swiper-cards { overflow: clip; }
    }
    .atuation {
        position: relative;
        padding-block: 231px 127px;
        margin-top: -163px;
        text-align: center;

        @media (max-width: 1100px) {
            padding-block: 40.9px 53.9px;
            margin-top: 0;

            .container {
                padding-inline: 25px;
            }
        }
        &::after {
            content: "";
            position: absolute;
            inset: 0;
            background-color: #28464a;
            opacity: 1;
            mix-blend-mode: multiply;
            z-index: -2;
        }
        &::before {
            content: "";
            position: absolute;
            inset: 0;
            background: linear-gradient(111.77deg, #1B5053 2.4%, rgba(27, 80, 83, 0) 93.16%);
            z-index: -1;
        }
        &--text {
            .title {
                width: fit-content;
                margin-inline: auto;

                &::after {
                    left: 40%;

                    @media (max-width: 1100px) {
                        left: 37%;
                    }
                }
            }
            .text {
                color: #fff;
                max-width: 566px;
                margin-inline: auto;
                margin-block: 32px 70px;

                @media (max-width: 1100px) {
                    margin-block: 25px 35px;
                }
            }
        }
        &--grid {
            &-item {
                padding: 54px 47px;
                background: linear-gradient(
                    180deg,
                    #082425 0%,
                    rgba(8, 36, 37, 0) 134.63%
                );
                border-bottom: 3px solid #c47d53;
                min-height: 422px;
                transition: all .3s ease-in-out;

                @media (max-width: 1400px) {
                    min-height: 443px;
                }
                @media (max-width: 1200px) {
                    min-height: 534px;
                }
                @media (max-width: 1100px) {
                    padding: 32px 29.7px 30px;
                    min-height: auto;
                }
                &:hover {
                    background: #F4F6F6;

                    .grid-title {
                        color: #172223;
                    }
                    .grid-text {
                        color: #7D7D7D;
                        opacity: 1;
                    }
                }
                .grid-icon {
                    display: block;
                    margin-inline: auto;
                    width: 48px;
                    height: 48px;

                    @media (max-width: 1100px) {
                        width: 39px;
                        height: 38px;
                    }
                }
                .grid-title {
                    color: #fff;
                    font-size: 25px;
                    font-weight: 400;
                    line-height: 25px;
                    min-height: 50px;
                    margin-block: 24px 34px;

                    @media (max-width: 1100px) {
                        font-size: 22px;
                        font-weight: 400;
                        line-height: 22px;
                        min-height: 46px;
                        margin-block: 27.9px 14px;
                    }
                }
                .grid-text {
                    color: #fff;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 22px;
                    opacity: 0.7;

                    -webkit-line-clamp: 2;
                    line-clamp: 2;

                    @media (max-width: 1100px) {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }
            }
            .col-lg-4 {
                padding-inline: 4.6px;
            }
        }
        .btn-wrapper { margin-top: 44.8px; }
        .row-gap-2 {
            row-gap: 5.9px !important;

            @media (max-width: 1100px) {
                row-gap: 14.3px !important;
            }
        }
    }
    .block {
        padding: 84px 0 0;
        height: 911px;
        position: relative;
        isolation: isolate;

        @media (max-width: 992px) {
            margin-top: 50.9px;
            height: 100%;
            padding: 0;
            overflow: hidden;

            .container {
                margin: 0;
                padding: 0;
                max-width: 100%;
            }
            .col-lg-6, .col-12 { padding-inline: 0; }
        }
        &--text {
            padding-top: 52px;

            @media (max-width: 992px) {
                background-color: #fff;
                padding: 23.8px 25px 57.3px 42.7px;
                width: Min(94vw, 430px);

                position: absolute;
                top: 0;
                right: 0;
                z-index: 2;
            }
            .title {
                max-width: 351px;
                margin-block-end: 32px;

                @media (max-width: 992px) {
                    max-width: 252px;
                    margin-block-end: 21.6px;
                }
            }
            .text {
                max-width: 379.7px;

                @media (max-width: 992px) {
                    max-width: 100%;
                }
            }
            .button {
                margin-block-start: 94px;

                @media (max-width: 992px) {
                    margin-block-start: 38px;
                }
            }
        }
        &--img {
            @media (max-width: 992px) {
                padding-top: 420px;
            }
            .img-wrapper {
                padding: 84px 96px 98px 111px;
                max-width: Min(58vw, 1110px);
                height: 1040px;
                background-color: #fff;

                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;

                @media (max-width: 992px) {
                    padding: 0;
                    max-width: 100%;
                    height: 570px;

                    position: unset;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    .destaques {
        padding: 142px 77px 238px;
        position: relative;

        @media (max-width: 1100px) {
            padding: 96px 0 121.6px;
        }
        &::after {
            content: "";
            position: absolute;
            inset: 0;
            background: #1b5053;
            opacity: 0.95;
            mix-blend-mode: multiply;
            z-index: -2;
        }
        .container {
            text-align: center;

            @media (max-width: 1100px) {
                padding-inline: 25px;

            }
            .title {
                width: fit-content;
                margin-inline: auto;

                @media (max-width: 1100px) {
                    max-width: 340px;
                    margin-inline: auto;

                    br { display: none; }
                }
                &::after {
                    left: 45%;

                    @media (max-width: 1100px) {
                        left: 40%;
                    }
                }
            }
            .text {
                color: #fff;
                max-width: 550px;
                margin-inline: auto;
                margin-top: 35px;

                @media (max-width: 1100px) {
                    margin-top: 30px;
                }
            }
        }
        &-wrapper {
            margin-top: 96.5px;
            max-width: 1544.5px;
            margin-inline: auto;

            @media (max-width: 1100px) {
                margin-top: 56.5px;
                padding-inline: 25px;
            }
            .swiper {
                overflow-x: clip !important;
                overflow-y: visible !important;
                height: fit-content;

                &-slide {
                    max-width: 341px;
                }
                &-pagination {
                    bottom: -96px;

                    @media (max-width: 1100px) {
                        bottom: -57px;
                    }
                }
            }
            .card-item {
                @media (max-width: 1100px) {
                    text-align: center;

                    .button {
                        margin-inline: auto;
                    }
                }
                &-img {
                    width: 100%;
                    height: 215.91px;
                    object-fit: cover;
                    border-bottom: 3px solid #c47d53;
                }
                &-title {
                    color: #fff;
                    font-size: 30px;
                    font-weight: 700;
                    line-height: 40px;
                    margin-top: 12px;
                }
                &-text {
                    color: #fff;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 25px;
                    opacity: 0.7;
                    margin-block: 17.2px 56px;

                    @media (max-width: 1100px) {
                        margin-block: 12px 44px;
                    }
                }
            }
        }
    }
    .videos {
        padding: 152px 0 106px;

        @media (max-width: 1100px) {
            padding: 60px 0 45px;

            & > .container {
                padding-inline: 25px;
            }
        }
        .title {
            max-width: fit-content;
            margin-inline: auto;
            text-align: center;

            &::after {
                left: 40%;

                @media (max-width: 1100px) {
                    left: 31%;
                }
            }
        }
        .text {
            margin-block: 34px 55px;
            text-align: center;

            @media (max-width: 1100px) {
                margin-block: 15px 51px;
            }
        }
        .cards-item {
            &::after {
                background: linear-gradient(
                    180deg,
                    rgba(3, 36, 40, 0) 0%,
                    rgba(3, 36, 40, 0.7) 47.92%
                );
                mix-blend-mode: multiply;
            }
            .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                translate: -50% -50%;

                @media (max-width: 1100px) {
                    width: 57px;
                    aspect-ratio: 1;
                }
            }
        }
        &-title {
            color: #1b5053;
            font-size: 22px;
            font-weight: 700;
            line-height: 22px;
            margin-block: 24px 22px;
            max-width: 339px;

            @media (max-width: 1100px) {
                font-size: 20px;
                line-height: 18px;
                margin-block: 19.7px 18px;
            }
        }
        &-text {
            color: #7d7d7d;
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            max-width: 360px;

            @media (max-width: 1100px) {
                font-size: 16px;
                line-height: 22px;
                max-width: 321px;
            }
        }
        .row-gap-5 {
            row-gap: 52px !important;

            @media (max-width: 1100px) {
                row-gap: 41px !important;
            }
        }
        .btn-wrapper {
            margin-top: 96px;
            display: grid;
            place-items: center;

            @media (max-width: 1100px) {
                margin-top: 47px;
            }
        }
    }

    .text {
        color: #7d7d7d;
    }
}

// re-usable sections
.partners {
    padding: 215px 55px 0;
    background-color: #172223;
    position: relative;
    isolation: isolate;
    z-index: -1;

    @media (max-width: 1100px) {
        padding: 52.7px 0 138px;

        .container {
            margin-inline: 0;
            padding-inline: 25px;
        }
    }
    &::after {
        content: "";
        position: absolute;
        inset: 0;
        background: linear-gradient(
            98.04deg,
            #1b5053 -12.61%,
            rgba(27, 80, 83, 0) 50%
        );
        z-index: -3;
    }
    &--text {
        max-width: 806px;
        margin-bottom: 90px;

        @media (max-width: 1100px) {
            margin-bottom: 60px;
        }
    }
    &--img {
        .partner {
            max-width: 1808px;
            margin-inline: auto;
            translate: 0 66px;

            display: flex;
            flex-wrap: nowrap;

            @media (max-width: 1100px) {
                translate: 0 0;
            }
            &-item {
                padding: 25px 12px;
                position: relative;
                isolation: isolate;
                transition: all .5s ease;

                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                flex-grow: 1;
                flex-basis: 100%;
                max-width: 258px;
                height: 100%;

                @media (max-width: 1600px) {
                    padding: 25px 12px 12px;

                    .button {
                        font-size: 20px;

                        .btn-icon {
                            max-width: 40px;
                            max-height: 40px;
                        }
                    }
                }
                @media (max-width: 1300px) {
                    .button {
                        font-size: 16px;
                    }
                }
                @media (max-width: 991px) {
                    padding: 25px 32px;
                }
                @media (min-width: 991px) {
                    .button {
                        gap: 5px;
                    }
                }
                &:hover,
                .info .button:hover {
                    justify-content: center;

                    &::after {
                        inset: 0;
                        background: #172223;
                        mix-blend-mode: multiply;
                    }
                    .info {
                        height: fit-content;
                        opacity: 1;
                        visibility: visible;
                        overflow: visible;
                    }
                }
                &::after {
                    content: "";
                    position: absolute;
                    inset: 64% 0 0 0;
                    background: linear-gradient(
                        360deg,
                        #172223 21.96%,
                        rgba(23, 34, 35, 0) 100%
                    );
                    mix-blend-mode: multiply;
                    z-index: -1;
                    transition: all .2s ease-in-out;
                }
                h6 {
                    color: #fff;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 23px;
                    margin: 0;

                    @media (max-width: 1300px) {
                        font-size: 21px;
                    }
                }
                .info {
                    height: 0;
                    opacity: 0;
                    visibility: hidden;
                    overflow: hidden;
                    transition: all .5s ease;

                    .text {
                        color: #fff !important;
                        opacity: .7;
                        margin-block: 16px 20px;
                        line-height: 22px;

                        @media (max-width: 1600px) {
                            font-size: 12px;
                            line-height: 16px;
                            margin-block: 8px 12px;
                        }
                    }
                }
            }
        }
        .swiper {
            overflow-x: clip !important;
            overflow-y: visible !important;
            max-width: 100%;
            max-height: 100%;

            &-slide {
                aspect-ratio: 9 / 16;

                @media (max-width: 991px) {
                    width: 258px;
                    height: 312px;
                    aspect-ratio: none;
                }
            }
            &-wrapper {
                @media (min-width: 991px) {
                    justify-content: center;
                }
            }
            &-button-next,
            &-button-prev {
                display: none;
                &::after { display: none; }

                @media (max-width: 1100px) {
                    display: inline-flex;
                    position: initial;

                    img { filter: brightness(0) invert(1); }
                }

            }
            &-button-prev {
                img { transform: rotate(180deg); }
            }
            &-button-next { translate: 0 -1px; }
            .btns-wrapper {
                translate: 0 53px;
                display: flex;
                flex-direction: row-reverse;
                justify-content: center;
                align-items: center;
                gap: 55px;
            }
        }
    }
}
.floating {
    margin: 181px 213px 0 0;
    position: relative;

    @media (max-width: 1100px) {
        margin: 0;
        padding: 140px 0 0;
    }
    .content--image{
        display: flex;
        overflow: hidden;
        position: relative;
        height: 807px;

        @media (max-width: 1100px) {
            height: 633px;
        }
    }
    &-div {
        background-color: #fff;
        padding: 0 150px 133px;

        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;
        width: Min(67vw, 1166px);
        translate: 213px -1px; // margin value

        @media (max-width: 1100px) {
            padding: 33.8px 25px 38.9px 42.7px;
            top: 32px;
            width: Min(94vw, 430px);
            translate: 0 0;
        }
        &-wrapper {
            max-width: 463px;

            @media (max-width: 1100px) {
                max-width: 100%;
            }
            .title {
                margin-bottom: 35px;

                @media (max-width: 1100px) {
                    margin-bottom: 17.3px;
                }
            }
            .button {
                margin-top: 48px;

                @media (max-width: 1100px) {
                    margin-top: 23px;
                }
            }
        }
    }
    .bg-img {
        position: unset;
        height: 807px;

        @media (max-width: 1100px) {
            height: 633px;
        }
    }
    .bg-shape {
        position: absolute;
        right: 0;
        bottom: 75px;
        z-index: 1;

        @media (max-width: 1100px) {
            display: none;
        }
    }
}
.branches {
    position: relative;
    height: 703px;

    @media (max-width: 1100px) {
        height: 912px;
    }
    .swiper {
        max-width: 100%;
        max-height: 100%;

        &-slide { height: 703px;
        &::after{
            content: '';
            position: absolute;
            inset: 0;
            background: linear-gradient(90deg, #172223 4.3%, rgba(23, 34, 35, 0) 88.56%);
            mix-blend-mode: multiply;
            z-index: 1;
        }}
        &-pagination {
            &-bullets {
                left: 7.292vw;
                right: inherit;

                @media (max-width: 1100px) {
                    display: none;
                }                
            }
        }
    }
    &--floating {
        position: absolute;
        right: 0;
        bottom: -133px;
        z-index: 2;
        background-color: #fff;
        padding: 78px 154px 95px;
        width: Min(1343px, 80vw);
        display: flex;
        justify-content: flex-start;

        @media (max-width: 1100px) {
            padding: 46.3px 74px 68px 42.7px;
            bottom: 0;
            width: Min(400px, 94vw);
        }

        &-wrapper {
            max-width: 890px;

            @media (max-width: 1100px) {
                max-width: 100%;

                .col-lg-4 + .col-lg-4 {
                    margin-top: 31.8px;
                }
            }
            .title {
                margin-bottom: 65.5px;

                @media (max-width: 1100px) {
                    margin-bottom: 47.7px;
                }
            }
            h6, li {
                font-size: 18px;
                line-height: 25px;

                @media (max-width: 1100px) {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
            .state {
                color: #C47D53;
                font-weight: 700;
            }
            ul {
                min-height: 125px;

                @media (max-width: 1100px) {
                    min-height: 100%;
                }
            }
            li {
                color: #7D7D7D;
                font-weight: 400;
            }
            .button {
                margin-top: 60px;

                @media (max-width: 1100px) {
                    display: none;
                }
            }
        }
    }
}
.sign-in {
    padding: 207px 0 152px;
    background-color: #172223;
    position: relative;
    isolation: isolate;

    @media (max-width: 1100px) {
        padding: 61.7px 0 54.7px;

        & > .container {
            padding-inline: 25px;
        }
    }
    &::after {
        content: '';
        position: absolute;
        inset: 0;
        background: linear-gradient(98.04deg, #1B5053 -12.61%, rgba(27, 80, 83, 0) 50%);
        z-index: -1;
    }
    .text {
        color: #fff !important;
        max-width: 623px;

        @media (max-width: 1100px) {
            max-width: 297px;
            margin-top: 25.8px;
        }
    }
    form {
        @media (max-width: 1100px) {
            .btn-wrapper {
                justify-content: start !important;
                margin-top: 62px;
            }
        }
        .form-control {
            border: 1px solid #C47D53;
            border-radius: 0;
            padding: 36px 33px 33px;
            background: linear-gradient(0deg, rgba(23, 34, 35, 0.93), rgba(23, 34, 35, 0.93));

            width: 100%;
            flex-grow: 1;

            @media (max-width: 1100px) {
                padding: 24px 33px;
                margin-top: 9.6px;
            }
            &, &::placeholder {
                color: #fff;
                font-size: 20px;
                font-weight: 400;
                line-height: 16px;

                @media (max-width: 1100px) {
                    font-size: 18px;
                    line-height: 16px;
                }
            }
        }
        .form-check {
            &-input {
                border: 1px solid #C47D53;
                border-radius: 0;
                background-color: #172223ED;
            }
            &-label {
                color: #fff;
                font-size: 17px;
                font-weight: 300;
                line-height: 20px;
                max-width: 880px;

                @media (max-width: 1100px) {
                    font-size: 14px;
                }
            }
        }
    }
    .mt-5 {
        margin-top: 63px !important;

        @media (max-width: 1100px) {
            margin-top: 16.8px !important;
        }
    }
    .mt-4 {
        margin-top: 53px !important;

        @media (max-width: 1100px) {
            margin-top: 25.8px !important;
        }
    }
}
.cards-item {
    position: relative;
    padding: 23px 47px 23px 31px;
    height: 322px;
    overflow: hidden;

    display: flex;
    align-items: flex-end;
    border-bottom: 3px solid #c47d53;

    @media (max-width: 1100px) {
        padding: 21px 36.3px 21px 25px;
        height: 263px;
    }
    &:hover {
        .bg-img { scale: 1.1; }
        .read-more {
            opacity: 1;
            visibility: visible;
        }
    }
    &::after {
        content: "";
        position: absolute;
        inset: 0;
        background: linear-gradient(
            0deg,
            #28464a 26.14%,
            rgba(40, 70, 74, 0) 75.84%
        );
        z-index: -1;
    }
    .bg-img { transition: all .3s ease; }
    .cards-item-title {
        color: #fff;
        font-size: 22px;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 20px;

        @media (max-width: 1100px) {
            font-size: 20px;
            font-weight: 700;
            line-height: 18px;
            margin-bottom: 18px;
        }
    }
    .cards-item-author,
    .cards-item-location {
        display: flex;
        align-items: center;
        gap: 8.5px;

        @media (max-width: 1100px) {
            gap: 6px;

            img {
                width: 18.82px;
                aspect-ratio: 1;
            }
        }
        p {
            color: #fff;
            font-size: 18px;
            font-weight: 400;
            line-height: 25px;
            opacity: 0.5;

            @media (max-width: 1100px) {
                font-size: 16px;
                line-height: 25px;
            }
        }
    }
    .cards-item-location {
        margin-top: 9.8px;
    }
    .date {
        position: absolute;
        top: 20px;
        left: 31px;
        height: 28px;
        width: 117px;
        background-color: #c47d53;

        display: grid;
        place-items: center;

        color: #fff;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;

        @media (max-width: 1100px) {
            top: 16px;
            left: 22px;
            height: 23px;
            width: 118px;
        }
    }
    .cat-icon {
        position: absolute;
        top: 23px;
        right: 20.9px;

        width: 56px;
        aspect-ratio: 1;
        background-color: #C47D53;

        display: grid;
        place-items: center;

        @media (max-width: 1100px) {
            top: 20px;
            right: 20px;
        }
    }
    .read-more {
        position: absolute;
        bottom: 16px;
        right: 31px;
        opacity: 0;
        visibility: hidden;
        transition: all .5s ease;

        color: #fff;
        font-size: 22px;
        font-weight: 700;
        line-height: 50px;

        &:hover {
            color: #C47D53;
        }
        @media (max-width: 1100px) {
            position: unset;
            margin-top: 12px;
            opacity: 1;
            visibility: visible;
        }
    }
    .cards-item-author + .cards-item-author { margin-top: 8px; }
}
.socials {
    img {
        transition: all .3s ease;

        &:hover {
            filter: invert(56%) sepia(17%) saturate(2211%) hue-rotate(338deg) brightness(96%) contrast(87%);
        }
    }
}
.conteudo {
    padding-block: 161px 131px;
    position: relative;
    z-index: -2;

    @media (max-width: 1100px) {
        padding-block: 60px 72px;

        & > .container {
            padding-inline: 25px;
        }
    }
    .title {
        max-width: fit-content;
        margin-inline: auto;

        &::after {
            left: 40%;

            @media (max-width: 1100px) {
                left: 33%;
            }
        }
    }
    .text {
        margin-block: 34px 70px;

        @media (max-width: 1100px) {
            margin-block: 15px 36.9px;
            max-width: 302px;
            margin-inline: auto;
        }
    }
    .title,
    .text {
        text-align: center;
    }
    .container > .row > .col-lg-4 {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }
    &-title {
        color: #28464a;
        font-size: 30px;
        font-weight: 700;
        line-height: 40px;

        @media (max-width: 992px) {
            text-align: center;
            margin-bottom: 0;
        }
    }
    &-sm {
        display: flex;
        gap: 21px;

        &:hover {
            .conteudo-sm-img { scale: 1.1; }
        }
        .img-wrapper {
            min-width: 138px;
            min-height: 98px;
            max-width: 138px;
            max-height: 98px;
            overflow: hidden;
        }
        &-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all .3s ease;
        }
        &-text {
            color: #28464a;
            font-size: 17px;
            font-weight: 700;
            line-height: 16px;
            margin-bottom: 10px;
        }
        .date {
            height: 23px;
            width: 98px;
            background-color: #c47d53;

            display: grid;
            place-items: center;

            color: #fff;
            font-size: 15px;
            font-weight: 400;
            line-height: 25px;
        }
    }
    .btn-wrapper {
        display: none;

        @media (max-width: 992px) {
            display: flex !important;
            justify-content: center;
        }
    }
    @media (max-width: 992px) {
        .col-lg-4 + .col-lg-4 {
            margin-top: -40px;
        }
        .col-12 {
            display: none;
        }
    }
}

// header css
#header {
    opacity: 1;
    translate: 0 0;
    transition: all .5s ease-in-out;

    &.hidden {
        opacity: 0;
        pointer-events: none;
        translate: 0 -100%;
        transition: all .5s ease-in-out;
    }
}