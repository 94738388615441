#policy {
    #bannerHero {
        .breadcumbs {
            ul li {
                &:last-child {
                    a {
                        @media (max-width: 400px) {
                            max-width: 265px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            overflow: hidden;
                        }
                    }
                    img { margin-right: 12px; }                    
                }
            }
        }
        .socials {
            bottom: 93px;
        }
    }

    .wrapper { 
        padding-bottom: 120px;

        @media (max-width: 1100px) {
            padding-bottom: 60px;
        }
    }
    .container {
        padding-inline: 25px;
    }
    h2 {
        color: #172223;
        font-size: 45px;
        font-weight: 300;
        line-height: 40px;

        text-align: center;
        width: fit-content;
        margin-inline: auto;
    
        position: relative;
        padding-top: 38px;
        margin-block: 120px 60px;
    
        @media (max-width: 1100px) {
            padding-top: 20px;
            margin-block: 60px 44px;
            text-align: start;
            width: 100%;

            &::after {
                left: 0 !important;
            }
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 40%;
            background-color: #C47D53;
            width: 66px;
            height: 3.9px;
        }
        strong {
            color: inherit;
            font-weight: 700;
            line-height: inherit;
        }                
    }
    p {
        color: #7D7D7D;
        font-size: 18px;
        font-weight: 400;

        @media (max-width: 1100px) {
            font-size: 16px;
        }
    }
    .policy {
        p { 
            line-height: 22px;
        }
        p + p { margin-top: 32px; }
    }
    .terms {
        h2 + p {
            line-height: 22px;
            text-align: center;
            max-width: 600px;
            margin-inline: auto;
            margin-bottom: 96px;

            @media (max-width: 1100px) {
                text-align: start;
                max-width: 100%;
                margin-bottom: 60px;
            }
        }
        h3 {
            color: #C47D53;
            font-size: 25px;
            font-weight: 400;
            line-height: 25px;
            margin-bottom: 20px;
        }
        p + h3 {
            margin-top: 32px;
        }
        p + p { 
            margin-top: 20px; 
        }
        p {
            line-height: 25px;

            @media (max-width: 1100px) {
                line-height: 22px;
            }
        }
        .row { row-gap: 32px; }
    }
}