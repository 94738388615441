#modal-zoom-images{
    position: fixed;
    inset: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.6);

    transform: scale(0);
    opacity: 0;
    transition: 0.4s;
    z-index: -100;

    display: flex;
    align-items: center;
    justify-content: center;

    &.activate-modal{
        opacity: 1;
        transform: scale(1);
        z-index: $zIndex-modal;
    }

    #btn-close__zoom-modal{
        position: absolute;
        right: 25px;
        top: 25px;

        width: 40px;
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 599px) {
            top: 10px;
            right: 10px;
        }

        i{
            font-size: 25px;
            color: rgb(226, 226, 226);
        }
    }


    #image__zoom-modal{
        width: 80vw;
        height: 80vh;
        object-fit: contain;

        @media (max-width: 599px) {
            width: 90vw;
        }
    }

}

// All images with 'images--zoom class //
.images--zoom{
    cursor: pointer;
}