#about{
    .title-center{
        font-size: 45px;
        font-weight: 300;
        line-height: 40px;
        position: relative;
        padding-top: 49px;
        margin: 0 auto;
        text-align: center;
        @media (max-width: 1100px) {
            font-size: 30px;
            line-height: 1;
            padding-top: 30px;
        }
        &::after {
            content: '';
            position: absolute;
            background-color: #C47D53;
            width: 66px;
            height: 3.9px;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        strong {
            color: inherit;
            font-weight: 700;
            line-height: inherit;
        }
    }
    .title-start{
        font-size: 45px;
        font-weight: 300;
        line-height: 40px;
        position: relative;
        padding-top: 49px;
        margin: 0 auto;
        @media (max-width: 1100px) {
            font-size: 30px;
            line-height: 1;
            padding-top: 30px;
        }
        &::after {
            content: '';
            position: absolute;
            background-color: #C47D53;
            width: 66px;
            height: 3.9px;
            top: 0;
            left: 0;
        }
        strong {
            color: inherit;
            font-weight: 700;
            line-height: inherit;
        }
    }
    .text-thin{
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        margin: 0 auto;
        text-align: center;
        strong{
            color: #1B5053;
        }
        @media (max-width: 1100px) {
            font-size: 16px;
            line-height: 22px;
        }
    }
    .grey-color{
        color: #7D7D7D;
    }
    .main-color{
     color: #172223;
    }

    .video{
        padding-top: 120px;
        @media (max-width: 1100px) {
            padding-top: 60px;
        }
        .box-text{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 40px;
            @media (max-width: 1100px) {
                gap: 20px;
            }
            .title-center{
                max-width: 555px;
            }
            .text-thin{
                max-width: 767px;
                margin-bottom: 72px;
                @media (max-width: 1100px) {
                    margin-bottom: 32px;
                }
            }
        }
        .video-container{
            position: relative;
            margin-bottom: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            .img-fluid{
                max-height: 35.365vw;
            }
            @media (max-width: 1100px) {
                .img-fluid{
                    height: 310px;
                    object-fit: cover;
                }
            }
            .video-button{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                @media (max-width: 1100px) {
                    width: 60px;
                    height: 60px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                img{
                    transition: all 0.3s ease-out;
                }
                &:hover{
                    img{
                        transform: scale(1.2);
                    }
                }
            }
        }
    }

    .pilares{
        min-height: 1285px;
        position: relative;
        top: -1px;
        padding-top: 230px;
        margin-bottom: 300px;
        isolation: isolate;

        &::after {
            content: "";
            position: absolute;
            inset: 0;
            background-color: #28464a;
            opacity: 1;
            mix-blend-mode: multiply;
            z-index: -2;
        }
        &::before {
            content: "";
            position: absolute;
            inset: 0;
            background: linear-gradient(111.77deg, #1B5053 2.4%, rgba(27, 80, 83, 0) 93.16%);
            z-index: -1;
        }
        .box-text{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 40px;
            margin-bottom: 45px;
            @media (max-width: 1100px) {
                gap: 20px;
                margin-bottom: 20px;
            }
            p{
                max-width: 555px;
            }
        }
        .bg-img-pilar{
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            z-index: -2;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .box-start{
            margin-top: 116px;
        }
        .card-pilar{
            display: flex;
            flex-direction: column;
            gap: 23.5px;
            background: #fff;
            border-bottom: 4px solid #C47D53;
            transition: all 0.3s ease-out;
            align-items: center;
            padding-inline: 23px;
            padding-bottom: 50px;
            padding-top: 54px;

            @media (max-width: 1400px) {
                min-height: 405px;
            }
            img{
                width: 50px;
                height: 50px;
            }
            &--text{
                font-size: 18px;
                line-height: 25px;
                font-weight: 400;
                margin: 0 auto;
                text-align: center;
                color: #7D7D7D;

            }
            &--title{
                font-size: 25px;
                line-height: 25px;
                color: #1B5053;
            }
            &:hover{
                transform: translate(0, -10px);
            }
        }
    }

    .branches {
        height: 862px;

        @media (max-width: 1100px) {
            padding-top: 100px;

            .bg-img {
                height: 797px;
            }
            &--floating {
                bottom: auto;
                top: 0;
            }
        }
        &--floating {
            bottom: -78px;
        }
        .swiper {
            &-slide {
                &::after {
                    display: none;
                }
            }
        }
    }

    .partners--img {
        padding-inline: 55px;
        
        .partner {
            max-width: 1808px;
            margin-inline: auto;
            translate: 0 66px;

            display: flex;
            flex-wrap: nowrap;

            @media (max-width: 1100px) {
                translate: 0 0;
            }
            &-item {
                padding: 25px 12px;
                position: relative;
                isolation: isolate;
                transition: all .5s ease;

                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                flex-grow: 1;
                flex-basis: 100%;
                max-width: 258px;
                height: 100%;

                @media (max-width: 1500px) {
                    padding: 25px 12px 12px;

                    .button {
                        font-size: 20px;

                        .btn-icon {
                            max-width: 40px;
                            max-height: 40px;
                        }
                    }
                }
                @media (max-width: 1300px) {
                    .button {
                        font-size: 16px;
                    }
                }
                @media (max-width: 991px) {
                    padding: 25px 32px;
                }
                @media (min-width: 991px) {
                    .button {
                        gap: 5px;
                    }
                }
                &:hover,
                .info .button:hover {
                    justify-content: center;

                    &::after {
                        inset: 0;
                        background: #172223;
                        mix-blend-mode: multiply;
                    }
                    .info {
                        height: fit-content;
                        opacity: 1;
                        visibility: visible;
                        overflow: visible;
                    }
                }
                &::after {
                    content: "";
                    position: absolute;
                    inset: 64% 0 0 0;
                    background: linear-gradient(
                        360deg,
                        #172223 21.96%,
                        rgba(23, 34, 35, 0) 100%
                    );
                    mix-blend-mode: multiply;
                    z-index: -1;
                    transition: all .2s ease-in-out;
                }
                h6 {
                    color: #fff;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 23px;
                    margin: 0;

                    @media (max-width: 1300px) {
                        font-size: 21px;
                    }
                }
                .info {
                    height: 0;
                    opacity: 0;
                    visibility: hidden;
                    overflow: hidden;
                    transition: all .5s ease;

                    .text {
                        color: #fff !important;
                        opacity: .7;
                        margin-block: 16px 20px;
                        line-height: 22px;

                        @media (max-width: 1500px) {
                            font-size: 15px;
                            margin-block: 12px 16px;
                        }
                        @media (max-width: 1300px) {
                            font-size: 12px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
        .swiper {
            overflow-x: clip !important;
            overflow-y: visible !important;
            max-width: 100%;
            max-height: 100%;

            &-slide {
                aspect-ratio: 9 / 16;

                @media (max-width: 991px) {
                    width: 258px;
                    height: 312px;
                    aspect-ratio: none;
                }
            }
            &-wrapper {
                @media (min-width: 991px) {
                    justify-content: center;
                }
            }
            &-button-next,
            &-button-prev {
                display: none;
                &::after { display: none; }

                @media (max-width: 1100px) {
                    display: inline-flex;
                    position: initial;

                    img { filter: brightness(0) invert(1); }
                }

            }
            &-button-prev {
                img { transform: rotate(180deg); }
            }
            &-button-next { translate: 0 -1px; }
            .btns-wrapper {
                translate: 0 53px;
                display: flex;
                flex-direction: row-reverse;
                justify-content: center;
                align-items: center;
                gap: 55px;
            }
        }
    }
}
