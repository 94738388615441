#atuationDetail {
    #bannerHero {
        .text-container {
            .title {
                max-width: 680px;
            }
        }
        .breadcumbs {
            ul {
                li {

                    a {
                        img {
                            width: 20px;
                            height: 10.08px;                        
                        }                        
                    }
                    &:not(:last-child) {
                        a { white-space: nowrap; }
                    }
                    &:last-child {
                        a {
                            max-width: 302px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            overflow: hidden;
    
                            @media (max-width: 1100px) {
                                max-width: Min(500px, 100%);
                            }
                            img { 
                                margin-right: 20px;

                                @media (max-width: 1100px) {
                                    margin-right: 12px;
                                }
                            }
                        }
                    }               
                }
            }
        }
    }

    .floating {
        margin: 178px 200px 0 0;
        isolation: isolate;
        min-height: 807px;

        display: flex;
        justify-content: end;

        @media (max-width: 1100px) {
            margin: 37px 0 0;
            padding: 0;
            min-height: 677.4px;
        }
        .content--image {
            position: absolute;
            top: 0;
            right: 0;
            height: 807px;
            width: 100%;

            @media (max-width: 1100px) {
                height: 677.4px;
            }
        }
        &-div {
            position: unset;
            padding: 0 150px 200px;
            translate: 200px -1px;
            height: fit-content;

            @media (max-width: 1100px) {
                padding: 32px 25px 43px;
                translate: 0 0;
                top: 0;
            }
            p { color: #7D7D7D; }

            &-wrapper {
                max-width: 100%;

                .title { max-width: 463px; }
                .text { text-align: justify; }
            }
        }
        .bg-img { 
            position: absolute;
            top: 0;
            left: 0;
            height: 810px;
            z-index: -1;

            @media (max-width: 1100px) {
                height: 677.4px;
            }
        }
        .bg-shape {
            max-width: 500px;
            max-height: 745px;
            opacity: .4;

            @media (max-width: 1100px) {
                display: none;
            }
        }
    }
    .activities {
        padding: 60px 77px 80px 160px;

        @media (max-width: 1500px) {
            padding: 144.9px 77px 80px;
        }
        @media (max-width: 1100px) {
            padding: 60px 25px;
        }
        &-container {
            .title {
                max-width: 570px;
                margin-bottom: 6.1px;

                @media (max-width: 1100px) {
                    max-width: 350px;
                    margin-bottom: 0;
                }
            }
            .content--image{
                display: flex;
                overflow: hidden;
                position: relative;
                height: 100%;
            }
            &--text {
                padding-top: 53.9px;

                @media (max-width: 1100px) {
                    padding-top: 44px;
                }
                ul {
                    padding-left: 20px;
    
                    @media (max-width: 1100px) {
                        padding-inline: 20px;
                    }
                    li {
                        &::after {
                            content: '●';
                            color: #C47D53;
                            position: absolute;
                            left: -20px;
                            top: 0;
                        }
                    }
                }
                li, p {
                    position: relative;
                    color: #7D7D7D;
                }
                .text + .text,
                p + p,
                li + li { margin-top: 20px; }
            }
            &--img {
                width: 781px;
                height: 1008px;

                @media (max-width: 1100px) {
                    width: 100%;
                    height: 453px;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .wrapper {
                width: Min(50%, 726px);

                @media (max-width: 1100px) {
                    width: 100%;
                }
            }
            .col-12 .row { 
                justify-content: space-between;
                column-gap: 4vw;
                flex-wrap: nowrap;

                @media (max-width: 1100px) {
                    flex-wrap: wrap;
                    flex-direction: column;
                    gap: 60px;
                    align-items: center;
                }
            }
        }
    }
}

// reusable sections
.join {
    margin: 0 184px 80px 0;
    height: 793px;
    position: relative;

    @media (max-width: 1100px) {
        margin: 0 !important;
        height: 753px;

        .bg-img {
            height: 509px;
        }
    }
    .content--image{
        display: flex;
        overflow: hidden;
        position: relative;
        height: 100%;

        @media (max-width: 1100px) {
            height: 509px;
        }
    }
    &--floating {
        background-color: #fff;
        padding: 96px 130px;

        position: absolute;
        top: 91px;
        right: 0;
        z-index: 0;
        width: Min(67vw, 1099px);
        translate: 184px -1px; // margin value

        @media (max-width: 1100px) {
            padding: 60px 25px 61px 43px;
            width: Min(94vw, 400px);
            translate: 0 0;

            top: auto;
            bottom: 0;
        }
        &-wrapper {
            max-width: 400px;

            @media (max-width: 1100px) {
                max-width: 100%;
            }
            .title { 
                margin-bottom: 60px;

                @media (max-width: 1100px) {
                    margin-bottom: 44px;
                }
            }
            .text { color: #7D7D7D; }
            .button { margin-top: 60px; }
        }
    }
}