#team{
    .title-center{
        font-size: 45px;
        font-weight: 300;
        line-height: 40px;
        position: relative;
        padding-top: 49px;
        margin: 0 auto;
        text-align: center;
        @media (max-width: 1100px) {
            font-size: 30px;
            line-height: 1;
            padding-top: 30px;
        }
        &::after {
            content: '';
            position: absolute;
            background-color: #C47D53;
            width: 66px;
            height: 3.9px;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        strong {
            color: inherit;
            font-weight: 700;
            line-height: inherit;
        }
    }
    .title-start{
        font-size: 45px;
        font-weight: 300;
        line-height: 40px;
        position: relative;
        padding-top: 49px;
        margin: 0 auto;
        @media (max-width: 1100px) {
            font-size: 30px;
            line-height: 1;
            padding-top: 30px;
        }
        &::after {
            content: '';
            position: absolute;
            background-color: #C47D53;
            width: 66px;
            height: 3.9px;
            top: 0;
            left: 0;
        }
        strong {
            color: inherit;
            font-weight: 700;
            line-height: inherit;
        }
    }
    .text-thin{
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        margin: 0 auto;
        text-align: center;
        strong{
            color: #1B5053;
        }
        @media (max-width: 1100px) {
            font-size: 16px;
            line-height: 22px;
        }
    }
    .grey-color{
        color: #7D7D7D;
    }
    .main-color{
     color: #172223;
    }
    .equipe{
        padding-top: 120px;
        @media (max-width: 1100px) {
            padding-top: 60px;
        }
        .card-equipe{
            height: 357px;
            position: relative;
            max-width: 295px;
            margin-bottom: 32px;
            padding: 25px 32px;
            overflow: hidden;

            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            @media (max-width: 1400px) {
                padding: 18px 12px;
            }
            &:hover, .info .button:hover {
                &::after {
                    inset: 0;
                    background: #172223;
                    mix-blend-mode: multiply;
                }
                .info {
                    height: 100%;
                    opacity: 1;
                    visibility: visible;
                    overflow: visible;
                }
            }
            &::after {
                content: "";
                position: absolute;
                inset: 64% 0 0 0;
                background: linear-gradient(
                    360deg,
                    #172223 21.96%,
                    rgba(23, 34, 35, 0) 100%
                );
                mix-blend-mode: multiply;
                z-index: -1;
                transition: all .2s ease-in-out;
            }
            img:not(.btn-icon){
                filter: grayscale(100%);
                /* Transition effect for the filter property */
                transition: filter 0.3s ease-out;
                width: 100%;
                height: 100%;
                object-fit: cover;

                position: absolute;
                inset: 0;
                z-index: -3;
            }
            &--title{
                color: #fff;
                font-size: 24px;
                font-weight: 700;
                line-height: 23px;
                margin: 0;
            }
            .info {
                    height: 0;
                    opacity: 0;
                    visibility: hidden;
                    overflow: hidden;
                    transition: all .5s ease;

                    .text { 
                        color: #fff; 
                        opacity: .7;
                        margin-block: 16px;

                        @media (max-width: 1200px) {
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }
            }
        }
        .card-equipe-color{
            height: 357px;
            position: relative;
            max-width: 295px;
            position: relative;
            background: #1B5053;
            padding-top: 90px;
            padding-inline: 30px;
            padding-bottom: 33px;
            isolation: isolate;

            &:hover {
                &::after {
                    height: 100%;
                    mix-blend-mode: multiply;
                }
            }
            &::after{
                content: '';
                position: absolute;
                background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(255, 255, 255, 0.2));
                bottom: 0;
                left: 0;
                width: 100%;
                height: 30%;
                mix-blend-mode: multiply;
                pointer-events: none;
                z-index: -1;
                transition: all .3s ease-in-out;
            }
            &--title{
                color: #fff;
                font-size: 24px;
                font-weight: 700;
                line-height: 23px;
            }
            &--text{
            font-size: 18px;
            line-height: 22px;
            font-weight: 400;
            color: #fff;
            max-width: 90%;
            margin-top: 20px;
            margin-bottom: 60px;
            }
            &--btn{
                display: flex;
                color: #fff;
                font-size: 24px;
                font-weight: 700;
                line-height: 23px;
                margin-top: 28px;
                align-items: center;
                gap: 14px;
            }
        }
        .box-text{
            display: flex;
            flex-direction: column;
            gap: 32px;
            margin-bottom: 80px;
            p{
                max-width: 533px;
            }
        }
    }
    .consultoria{
        margin-top: 150px;
        margin-bottom: 150px;
        @media (max-width: 1100px) {
            margin-top: 60px;
            margin-bottom: 60px;
        }
        .box-text{
            display: flex;
            flex-direction: column;
            gap: 32px;
            margin-bottom: 60px;
            p{
                max-width: 469px;
            }
        }
        .card-equipe{
            height: 357px;
            max-width: 295px;
            position: relative;
            margin-bottom: 32px;
            padding: 25px 32px;
            isolation: isolate;

            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            @media (max-width: 1400px) {
                padding: 18px 12px;
            }
            &::after{
                content: '';
                position: absolute;
                background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(255, 255, 255, 0.2));
                inset: 64% 0 0 0;
                width: 100%;
                mix-blend-mode: multiply;
                transition: all .2s ease-in-out;
                z-index: -1;
            }
            &:hover, .info .button:hover {
                &::after {
                    inset: 0;
                    background: #172223;
                    mix-blend-mode: multiply;
                }
                .info {
                    height: 100%;
                    opacity: 1;
                    visibility: visible;
                    overflow: visible;
                }
            }
            img:not(.btn-icon){
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: -2;
            }
            &--title{
                color: #fff;
                font-size: 24px;
                font-weight: 700;
                line-height: 23px;
            }
            .text{
                color: #fff;
                font-size: 18px;
                line-height: 22px;
                font-weight: 400;
                z-index: 9;
            }
            .info {
                height: 0;
                opacity: 0;
                visibility: hidden;
                overflow: hidden;
                transition: all .5s ease;

                .text { 
                    color: #fff; 
                    opacity: .7;
                    margin-block: 16px;

                    @media (max-width: 1200px) {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
            }
        }
    }
}
