#footer {
    padding-block: 75px 0;
    background-color: #1B5053;

    .cat-title {
        color: #fff;
        font-size: 22px;
        font-weight: 700;
        line-height: 40px;

        position: relative;
        padding-bottom: 13.96px;
        margin-bottom: 32px;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: #C47D53;
            width: 42px;
            height: 4px;
        }
    }
    li {
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        line-height: 40px;
        opacity: .5;
        width: fit-content;
        transition: all 50ms ease;

        &:hover {
            opacity: 1;
        }
    }
    .row { justify-content: space-between; }
    .links { max-width: 156px; }
    .areas { max-width: 324px; }
    .contato { max-width: 266px; }
    .endereco { 
        max-width: 319px;

        li + li { margin-top: 26px; }
        .socials { margin-top: 59px; }
        .gap-3 { gap: 24px !important; }
    }
    .logos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    .mt-6 { margin-top: 123px !important; }
    .bottom {
        background-color: #093C3F;
        padding-block: 29.3px;
        margin-top: 112px;

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;            
        }
        h6, a {
            color: #fff;
            font-size: 18px;
            font-weight: 400;
            line-height: 40px;
            margin: 0;
        }
        a {
            text-decoration: none;
            transition: all .3s ease-in-out;
            
            &:hover { color: #C47D53; }
        }
        img {
            transition: all .5s ease-in-out;

            &:hover {
                transform: rotate(360deg) scale(1.1);
            }
        }
        &-text, &-anchor { max-width: fit-content; }
        .back-to-top { display: none; }
    }

    @media (max-width: 1100px) {
        padding-block: 40px 0;

        & > .container {
            padding-inline: 25px;
        }
        .cat-title {
            font-size: 20px;
            line-height: 40px;
        }
        li {
            font-size: 16px;
            line-height: 22px;
        }
        .contato { max-width: 240px; }
        .endereco { 
            max-width: 295px;

            .socials { 
                margin-top: 60px;

                img {
                    max-width: 100%;
                    height: 31px;
                }
            }
            .gap-3 { gap: 33px !important; }
        }
        .logos {
            gap: 43px 78px;

            img {
                &:nth-child(1) {
                    width: 161.6px;
                    height: 90.9px;
                }
                &:nth-child(2) {
                    width: 86.97px;
                    height: 60.84px;
                }
                &:nth-child(3) {
                    width: 165.78px;
                    height: 59.04px;                   
                }
                &:nth-child(4) {
                    width: 56.96px;
                    height: 61.64px;                                     
                }
                &:nth-child(5),
                &:nth-child(6) {
                    width: 120.65px;
                    height: 50.35px;                                                     
                }
            }
        }
        .bottom {
            padding-block: 32.8px 54.2px;
            margin-top: 67.7px;

            .container {
                flex-direction: column;
                text-align: center;
                justify-content: center;
                align-items: center;
                gap: 29.2px;
            }
            &-text {
                flex-direction: column;
                gap: 16px !important;
            }
            .back-to-top {
                display: flex;
                margin-top: 4px;

                img {
                    filter: brightness(0) invert(1);
                    transform: rotate(-90deg);
                }
            }
        }
        .links, .areas { display: none; }
        .mt-6 { margin-top: 60px !important; }
        .col-lg-3 + .col-lg-3 { margin-top: 46px; }
        .logos { justify-content: start; }
    }
}