#articleDetail {
    display: grid;

    .wrapper {
        max-width: 1440px;
        margin: 120px 160px 145px;
        position: relative;
        overflow: hidden;
        justify-self: flex-end;

        @media (max-width: 1500px) {
            margin-inline: 70px;
            justify-self: center;
        }
        @media (max-width: 1100px) {
            margin: 0;
            padding: 96px 25px 60px;
        }
        .container {
            display: flex;
            justify-content: space-between;
            gap: 56px;
            padding-inline: 0;

            @media (min-width: 1400px) {
                max-width: 1440px !important;
            }
            @media (max-width: 1100px) {
                flex-direction: column;
                gap: 60px;
            }
        }
        .post {
            h2 {
                color: #172223;
                font-size: 45px;
                font-weight: 300;
                line-height: 40px;
            
                position: relative;
                padding-top: 38px;
                margin-bottom: 20px;
            
                @media (max-width: 1100px) {
                    padding-top: 20px;
                    margin-bottom: 32px;
                }
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: #C47D53;
                    width: 66px;
                    height: 3.9px;
                }
                strong {
                    color: inherit;
                    font-weight: 700;
                    line-height: inherit;
                }                
            }
            span {
                color: #7D7D7D;
                font-size: 22px;
                font-style: italic;
                font-weight: 400;
                line-height: 50px;
            }
            p, li {
                color: #7D7D7D;
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;

                @media (max-width: 1100px) {
                    font-size: 16px;
                }
            }
            h4 {
                color: #172223;
                font-size: 22px;
                font-weight: 700;
                line-height: 50px;
                margin-block: 40px 20px;
            }
            ul {
                strong { font-weight: 700; }
            }
            p + p,
            li + li { margin-top: 12px; }
            &-footer {
                display: flex;
                justify-content: space-between;
                margin-top: 60px;
                
                @media (max-width: 1100px) {
                    flex-direction: column;
                    align-items: center;
                    gap: 44px;
                }
                &--share {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    @media (max-width: 1100px) {
                        margin-top: 44px;
                    }
                    h6 {
                        color: #7D7D7D;
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 30px;

                        @media (max-width: 1100px) {
                            font-size: 18px;
                            line-height: 22px;
                        }
                    }
                    .share-icons {
                        display: flex;
                        align-items: center;
                        gap: 20px;

                        .icon {
                            transition: all .3s ease;

                            @media (max-width: 1100px) {
                                width: 18px;
                                height: 18px;
                            }
                            &:hover {
                                filter: brightness(0) opacity(70%);
                            }
                        }
                    }
                }
            }
        }
        .aside {
            min-width: Min(100%, 407px);
            max-width: 407px;

            @media (max-width: 1500px) {
                min-width: min(100%, 326px);
                max-width: min(100%, 326px);
            }
            @media (max-width: 1100px) {
                min-width: min(100%, 340px);
                max-width: min(100%, 340px);
            }
            &-searchbar {
                @media (max-width: 1100px) {
                    position: absolute;
                    top: 20px;
                    right: 25px;
                }
                .input-group,
                .form-outline { 
                    width: 100%;
                    flex-wrap: nowrap;

                    @media (max-width: 1100px) {
                        display: flex;
                        flex-wrap: wrap-reverse;
                    }
                }
                .form-control {
                    width: 100%;
                    height: 100%;
                    background-color: #F4F6F6;
                    border: none;
                    border-radius: 0;
                    padding: 13px 20px;
    
                    &, &::placeholder {
                        color: #7D7D7D;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 30px;
                    }
                    &:focus { box-shadow: none; }
                    @media (max-width: 1100px) {
                        position: absolute;
                        top: 0;
                        right: 56px;
                        width: 350px;
                        max-width: 75vw;
                    }
                }
                .btn-search {
                    border-radius: 0;
                    padding: 13px 20px;
                    display: grid;
                    place-items: center;
                    background-color: #C47D53;
    
                    @media (max-width: 1100px) {
                        width: 56px;
                        height: 56px;
                    }
                }
            }
            &-related {
                margin-top: 60px;

                @media (max-width: 1100px) {
                    margin-top: 0;
                }
                &--news {
                    display: flex;
                    flex-direction: column;
                    gap: 32px;

                    .conteudo-sm {
                        display: flex;
                        gap: 21px;

                        @media (max-width: 1100px) {
                            gap: 12px;
                        }
                        &-img {
                            max-width: 138px;
                            max-height: 98px;

                            @media (max-width: 1100px) {
                                max-width: 120px;
                                max-height: 93px;
                            }
                        }
                        &-text {
                            color: #28464a;
                            font-size: 17px;
                            font-weight: 700;
                            line-height: 16px;
                            margin-bottom: 10px;
                            min-width: 150px;

                            @media (max-width: 1100px) {
                                font-size: 14px;
                                line-height: 15px;
                                min-width: auto;
                                max-width: 100%;
                                margin-bottom: 8px;
                            }
                        }
                        .date {
                            height: 23px;
                            width: 98px;
                            background-color: #c47d53;
            
                            display: grid;
                            place-items: center;
            
                            color: #fff;
                            font-size: 15px;
                            font-weight: 400;
                            line-height: 25px;
                        }                        
                    }
                }
            }
            &-newsletter {
                background-color: #F4F6F6;
                width: 100%;
                padding: 60px 30px;
                margin-top: 60px;
    
                @media (max-width: 1100px) {
                    display: none;
                }
                .title {
                    font-size: 30px;
                    line-height: 38px;
                    max-width: 290px;
                }
                .text {
                    color: #7D7D7D;
                    margin-block: 20px 60px;
                }
                .form-wrapper {
                    form {
                        .form-control {
                            padding: 20px 32px;
                            background-color: #E8EEEE4D;
                            border: 1px solid #C47D53;
                            border-radius: 0;
                            width: 100%;
    
                            &, &::placeholder {
                                color: #7D7D7D;
                                font-size: 18px;
                                font-weight: 400;
                                line-height: 14px;                        
                            }
                            &:focus { box-shadow: none; }
                        }
                        .form-check {
                            &-input {
                                border: 1px solid #C47D53;
                                background-color: #E8EEEE4D;
                                
                                &:checked[type=checkbox] {
                                    $bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
                                    background-image: $bs-form-check-bg-image;
                                }                                
                            }
                            &-label {
                                color: #7D7D7D;
                                font-size: 14px;
                                font-weight: 300;
                                line-height: 18px;
                            }
                        }
                        .btn-wrapper {
                            margin-top: 36px;
                        }
                    }
                    .mt-3 { margin-top: 20px !important; }
                    .mt-5 { margin-top: 32px !important; }
                }
            }
            h6 {
                color: #1B5053;
                font-size: 22px;
                font-weight: 700;
                line-height: 50px;
                margin-bottom: 20px;
            }
        }
    }
    .join {
        margin: 0 184px 0 0;
    }
}