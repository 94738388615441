.success-message-forms{
    width: 100%;
    height: 100%;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10000;
    display: none;
    opacity: 1;
    &.open-modal{
        display: flex;
    }    

    &--container{
        position: absolute;
        transform: translateY(-65%) translateX(-50%);
        background-color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        top: 50%;
        left: 50%;

        width: 500px;
        padding: 30px 50px;
        gap: 20px;

        #btn--close-success-message{
            position: absolute;
            top: 2vw;
            right: 2vw;
            
            ion-icon{
                font-size: 25px;
                color: #1B5053;
            }
            
        }

        .icon{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 5vw;
            aspect-ratio: 1;
            border-radius: 50%;
            background-color: $white;
            font-size: 60px;
            color: #C47D53;
        }

        .description {
            font-size: 18px;
            color: #7d7d7d;
            text-align: center;
        }

        .confirm{
            @media (max-width: 799px) {
                margin-top: 40px;
            }
        }
    }

    @media (max-width: 799px) {
        &--container{
            width: 95%;
            padding-inline: 20px;
            padding-top: 40px;
            padding-bottom: 40px;
            gap: 30px;

            #btn--close-success-message {
                img {
                    width: 20px;
                }
            }

            .icon{
                width: 65px;
                height: 65px;
            }

            .confirm {
                margin-top: 0px;
            }
        }
    }
}