#contentList {
    // search bar lib
    @import '~mdb-ui-kit/css/mdb.min.css';

    #bannerHero {
        .breadcumbs {
            ul li {
                &:nth-child(2) {
                    a {
                        @media (max-width: 1100px) {
                            max-width: 171px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            overflow: hidden;
                        }
                    }
                    img { margin-right: 12px; }
                }
            }
        }
    }

    .wrapper {
        display: flex;
        justify-content: space-between;
        gap: 61px;

        max-width: 1440px;
        margin: 120px 160px;
        overflow: hidden;

        @media (max-width: 1400px) {
            margin-inline: 60px;
            gap: 50px;
        }
        @media (max-width: 1100px) {
            flex-direction: column;
            justify-content: flex-start;
            gap: 94px;

            margin: 60px auto;
            padding-inline: 25px;
        }
    }
    .aside {
        width: Min(100%, 407px);
        position: relative;

        @media (max-width: 1100px) {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &-searchbar {
            position: relative;

            .input-group,
            .form-outline { 
                width: 100%;
                flex-wrap: nowrap;

                @media (max-width: 1100px) {
                    display: flex;
                    flex-wrap: wrap-reverse;
                }
            }
            .form-control {
                width: 100%;
                height: 100%;
                background-color: #F4F6F6;
                border: none;
                border-radius: 0;
                padding: 13px 20px;

                &, &::placeholder {
                    color: #7D7D7D;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 30px;
                }
                &:focus { box-shadow: none; }
                @media (max-width: 1100px) {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 350px;
                    max-width: 87vw;
                }
            }
            .btn-search {
                border-radius: 0;
                padding: 13px 20px;
                display: grid;
                place-items: center;
                background-color: #C47D53;

                @media (max-width: 1100px) {
                    width: 56px;
                    height: 56px;
                }
            }
        }
        &-categories {
            margin-block: 32px 60px;

            @media (max-width: 1100px) {
                margin: 0;

                .cat-wrapper {
                    display: flex;
                    max-height: 56px;
                }
            }
            .cat-title {
                color: #1B5053;
                font-size: 22px;
                font-weight: 700;
                line-height: 50px;
                margin-bottom: 11px;

                @media (max-width: 1100px) {
                    text-align: center;
                    display: none;
                }
            }
            .category {
                width: 100%;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                height: 81px;
                margin-top: 9px;

                @media (max-width: 1100px) {
                    height: 56px;
                    margin-top: 0;

                    &:nth-child(2),
                    &:nth-child(3) {
                        border-left: 1px solid #E7E7E7;
                    }
                    &.active {
                        .category-txt {
                            display: flex;
                            width: 100%;
                            padding-inline: 0;
                            background-color: transparent !important;
                            
                            position: absolute;
                            bottom: -101px;
                            left: 0;

                            p { color: #1B5053 !important; }
                        }
                    }
                }
                &:hover, &.active {
                    .category-txt {
                        background-color: #1B5053;

                        p { color: #fff; }
                    }
                    @media (max-width: 1100px) {
                        .category-icon {
                            background-color: #1B5053;

                            img { filter: brightness(0) invert(1); }
                        }
                    }
                }
                &-icon {
                    height: 100%;
                    width: 72px;
                    background-color: #C47D53;
                    transition: all .5s ease;

                    display: grid;
                    place-items: center;

                    @media (max-width: 1100px) {
                        background-color: #F4F6F6;

                        img { filter: invert(56%) sepia(17%) saturate(2211%) hue-rotate(338deg) brightness(96%) contrast(87%); }
                    }
                }
                &-txt {
                    height: 100%;
                    background-color: #F4F6F6;

                    display: flex;
                    align-items: center;
                    padding-inline: 20px;
                    flex-grow: 1;
                    transition: all .5s ease;

                    @media (max-width: 1100px) {
                        display: none;
                    }
                    p {
                        color: #7D7D7D;
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 50px;
                    }
                }
            }
        }
        &-newsletter {
            background-color: #F4F6F6;
            width: 100%;
            padding: 60px 30px;

            @media (max-width: 1100px) {
                display: none;
            }
            .title {
                font-size: 30px;
                line-height: 38px;
                max-width: 290px;
            }
            .text {
                color: #7D7D7D;
                margin-block: 20px 60px;
            }
            .form-wrapper {
                form {
                    .form-control {
                        padding: 20px 32px;
                        background-color: #E8EEEE4D;
                        border: 1px solid #C47D53;
                        border-radius: 0;
                        width: 100%;

                        &, &::placeholder {
                            color: #7D7D7D;
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 14px;                        
                        }
                        &:focus { box-shadow: none; }
                    }
                    .form-check {
                        &-input {
                            border: 1px solid #C47D53;
                            background-color: #E8EEEE4D;
                            
                            &:checked[type=checkbox] {
                                $bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
                                background-image: $bs-form-check-bg-image;
                            }
                        }
                        &-label {
                            color: #7D7D7D;
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 18px;
                        }
                    }
                    .btn-wrapper {
                        margin-top: 36px;
                    }
                }
                .mt-3 { margin-top: 20px !important; }
                .mt-5 { margin-top: 32px !important; }
            }
        }
    }
    .blog {
        &-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            place-items: center;
            gap: 20px;
            margin-bottom: 79.9px;
            
            @media (max-width: 1100px) {
                margin-bottom: 32px;
            }
            @media (max-width: 680px) {
                grid-template-columns: 1fr;
            }
            .cards-item {
                max-width: 476px;
                height: 366px;
                width: 100%;

                & > a:not(.read-more) {
                    display: flex;
                    align-items: flex-end;
                    height: 100%;
                }
                &::after {
                    background: #000;
                    opacity: .6;
                    mix-blend-mode: multiply;
                }
                &:has(.cards-item-location) {
                    .cards-item-author img {
                        width: 17.4px;
                        height: 17.4px;
                    }
                }
                @media (max-width: 1100px) {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-end;                    
                }
            }
        }
    }
}