#newsDetail {
    display: grid;

    .wrapper {
        max-width: 1440px;
        margin: 120px 160px 145px;
        position: relative;
        overflow: hidden;
        justify-self: flex-end;

        @media (max-width: 1500px) {
            margin-inline: 70px;
            justify-self: center;
        }
        @media (max-width: 1100px) {
            margin: 0;
            padding: 96px 25px 60px;
        }
        .container {
            display: flex;
            justify-content: space-between;
            gap: 56px;
            // margin-block-end: 0;
            padding-inline: 0;

            @media (min-width: 1400px) {
                max-width: 1440px !important;
            }
            @media (max-width: 1100px) {
                flex-direction: column;
                gap: 60px;
            }
        }
        .post {
            h2 {
                color: #172223;
                font-size: 45px;
                font-weight: 300;
                line-height: 40px;
            
                position: relative;
                padding-top: 38px;
                margin-bottom: 60px;
            
                @media (max-width: 1100px) {
                    padding-top: 20px;
                    margin-bottom: 32px;
                }
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: #C47D53;
                    width: 66px;
                    height: 3.9px;
                }
                strong {
                    color: inherit;
                    font-weight: 700;
                    line-height: inherit;
                }                
            }
            p, figcaption {
                color: #7D7D7D;
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;

                @media (max-width: 1100px) {
                    font-size: 16px;
                }
            }
            p + p { margin-top: 20px; }
            figure {
                margin-block: 40px;
            }
            img {
                max-width: 100%;
            }
            &-footer {
                margin-top: 52px;

                @media (max-width: 1100px) {
                    margin-top: 60px;
                }
                &--tags {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: 12px;
                    max-width: 519px;

                    @media (max-width: 1100px) {
                        max-width: 400px;
                    }
                    .tag {
                        height: 56px;
                        padding: 15.5px 28px;
                        border: 1px solid #7D7D7D66;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;

                        color: #7D7D7D;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 25px;

                        @media (max-width: 1100px) {
                            height: 46px;
                            padding: 12px 8px;

                            font-size: 14px;
                            line-height: 22px;
                        }
                    }
                }
                &--share {
                    margin-top: 60px;
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    @media (max-width: 1100px) {
                        margin-top: 44px;
                    }
                    h6 {
                        color: #7D7D7D;
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 30px;

                        @media (max-width: 1100px) {
                            font-size: 18px;
                            line-height: 22px;
                        }
                    }
                    .share-icons {
                        display: flex;
                        align-items: center;
                        gap: 20px;

                        .icon {
                            transition: all .3s ease;

                            @media (max-width: 1100px) {
                                width: 18px;
                                height: 18px;
                            }
                            &:hover {
                                filter: brightness(0) opacity(70%);
                            }
                        }
                    }
                }
            }
        }
        .aside {
            min-width: Min(100%, 407px);

            @media (max-width: 1500px) {
                min-width: min(100%, 320px);
                max-width: min(100%, 320px);
            }
            @media (max-width: 1100px) {
                min-width: min(100%, 340px);
                max-width: min(100%, 340px);
            }
            &-searchbar {
                @media (max-width: 1100px) {
                    position: absolute;
                    top: 20px;
                    right: 25px;
                }
                .input-group,
                .form-outline { 
                    width: 100%;
                    flex-wrap: nowrap;

                    @media (max-width: 1100px) {
                        display: flex;
                        flex-wrap: wrap-reverse;
                    }
                }
                .form-control {
                    width: 100%;
                    height: 100%;
                    background-color: #F4F6F6;
                    border: none;
                    border-radius: 0;
                    padding: 13px 20px;
    
                    &, &::placeholder {
                        color: #7D7D7D;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 30px;
                    }
                    &:focus { box-shadow: none; }
                    @media (max-width: 1100px) {
                        position: absolute;
                        top: 0;
                        right: 56px;
                        width: 350px;
                        max-width: 75vw;
                    }
                }
                .btn-search {
                    border-radius: 0;
                    padding: 13px 20px;
                    display: grid;
                    place-items: center;
                    background-color: #C47D53;
    
                    @media (max-width: 1100px) {
                        width: 56px;
                        height: 56px;
                    }
                }
            }
            &-related {
                margin-top: 60px;

                @media (max-width: 1100px) {
                    margin-top: 0;
                }
                &--news {
                    display: flex;
                    flex-direction: column;
                    gap: 32px;

                    .conteudo-sm {
                        display: flex;
                        gap: 21px;

                        @media (max-width: 1100px) {
                            gap: 12px;
                        }
                        &-img {
                            max-width: 138px;
                            max-height: 98px;

                            @media (max-width: 1100px) {
                                max-width: 120px;
                                max-height: 93px;
                            }
                        }
                        &-text {
                            color: #28464a;
                            font-size: 17px;
                            font-weight: 700;
                            line-height: 16px;
                            margin-bottom: 10px;
                            min-width: 150px;

                            @media (max-width: 1100px) {
                                font-size: 14px;
                                line-height: 15px;
                                min-width: auto;
                                max-width: 100%;
                                margin-bottom: 8px;
                            }
                        }
                        .date {
                            height: 23px;
                            width: 98px;
                            background-color: #c47d53;
            
                            display: grid;
                            place-items: center;
            
                            color: #fff;
                            font-size: 15px;
                            font-weight: 400;
                            line-height: 25px;
                        }                        
                    }
                }
            }
            &-tags {
                margin-top: 60px;

                &--wrapper {
                    display: flex;
                    gap: 8px;
                    flex-wrap: wrap;

                    .tag {
                        padding: 15.5px 28px;
                        height: 81px;
                        background-color: #F4F6F6;

                        display: grid;
                        place-items: center;

                        color: #7D7D7D;
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 50px;
                    }
                }
            }
            h6 {
                color: #1B5053;
                font-size: 22px;
                font-weight: 700;
                line-height: 50px;
                margin-bottom: 20px;
            }
        }
    }
    .join {
        margin: 0 184px 0 0;
    }
}

#bannerPosts {
    position: relative;
    margin-top: 106px; //navbar height
    // padding-top: min(160px, 16vh);
    min-height: Min(calc(100vh - 106px), 680px);
    isolation: isolate;

    @media (max-width: 1100px) {
        margin-top: 90px;
        padding-top: 69.7px;
        min-height: Min(calc(100vh - 90px), 509px);
    }
    &::after, &::before {
        content: '';
        position: absolute;
        inset: 0;
        mix-blend-mode: multiply;
        z-index: -1;
    }
    &::after {
        background: linear-gradient(90deg, rgba(27, 80, 83, 0.8) 3.28%, rgba(27, 80, 83, 0.8) 51.12%, rgba(27, 80, 83, 0) 84.69%);
    }
    &::before {
        background: linear-gradient(90deg, #1B5053 21.73%, rgba(27, 80, 83, 0) 54.81%);        
    }
    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        @media (max-width: 1100px) { 
            margin-inline: 0;
            padding-inline: 25px;
        }
    }
    .text-container {
        max-width: 100%;
        margin-block: auto;

        @media (max-width: 1100px) {
            margin-block: 0;
        }
        .title {
            font-size: 58px;
            font-weight: 700;
            line-height: 50px;
            padding-top: 0;
            margin-bottom: 29px;
            max-width: 1050px;

            @media (max-width: 1100px) {
                font-size: 35px;
                line-height: 1;
                margin-bottom: 12px;
                max-width: 100%;
            }
            &::after { display: none; }
        }
        .text { 
            color: #fff;
            max-width: 686px;

            @media (max-width: 1100px) {
                max-width: 100%;
            }
        }
        .icons-wrapper {
            gap: 32px;
            margin-top: 29px;

            @media (max-width: 1100px) {
                max-width: 340px;
                margin-top: 32px;
                gap: 12px;
                flex-wrap: wrap;
            }
            &--item {
                display: flex;
                align-items: center;
                gap: 13px;

                @media (max-width: 1100px) {
                    gap: 8px;
                }
                img {
                    width: 24px;
                    aspect-ratio: 1;

                    @media (max-width: 1100px) {
                        width: 16px;
                    }
                }
                p {
                    color: #fff;
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 50px;
                    opacity: .7;

                    @media (max-width: 1100px) {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
            }
        }
    }
    .breadcumbs {
        padding-bottom: 20px;

        ul {
            &, li > a {
                display: flex;
                align-items: center;
                gap: 20px;

                @media (max-width: 1100px) { gap: 12px; }
            }
            li {
                color: #fff;
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;
                transition: all .3s ease-in-out;

                @media (max-width: 1100px) {
                    font-size: 14px;
                    line-height: 22px;
                }
                a { 
                    text-decoration: none; 

                    img {
                        width: 20px;
                        height: 10.08px;                        
                    }
                }
                &:hover { opacity: 1; }
                &:not(:last-child) { opacity: .7; }
                &:last-child {
                    a {
                        max-width: 302px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;

                        @media (max-width: 1100px) {
                            max-width: Min(500px, 100%);
                        }
                        img { 
                            margin-right: 20px;

                            @media (max-width: 1100px) {
                                margin-right: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
    .socials {
        position: absolute;
        left: 138px;
        top: 187px;

        @media (max-width: 1700px) {
            left: 28px;
        }
        @media (max-width: 1480px) {
            left: auto;
            right: 60px;
        }
        @media (max-width: 1100px) {
            display: none !important;
        }
    }
}