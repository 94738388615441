#contact {
    #bannerHero {
        p {
            text-align: left;
        }
    }

    p {
        font-family: Bicyclette;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: #7D7D7D;
    }

    .contatos {
        padding: 110px 0 0 0;
        position: relative;
        z-index: 2;

        @media(max-width: 991px) {
            padding: 60px 0 0 0;
        }

        .title {
            text-align: center;
            margin-bottom: 42px;

            &::after {
                right: 0;
                margin: 0 auto;
            }
        }

        .cards-contato {
            margin-block: 80px -172px;

            @media(max-width: 991px) {
                position: inherit;
                bottom: inherit;
                margin-bottom: -150px;
                margin-inline: auto;
            }

            .card-contato {
                max-width: 421px;
                min-height: 354px;
                text-align: center;
                padding: 54px 50px 63px 50px;
                position: relative;
                background-color: #F4F6F6;
                transition: all .3s ease;

                @media(max-width: 1200px) {
                    min-height: 354px;
                }

                @media(max-width: 991px) {
                    margin-bottom: 10px;
                    max-height: 329px;
                }

                &:hover {
                    translate: 0 -5px;
                }

                h3 {
                    font-family: Bicyclette;
                    font-size: 25px;
                    font-weight: 400;
                    line-height: 25px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #C47D53;
                    margin-bottom: 18px;
                }

                p {
                    margin: 13px 0;
                }

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 3px;
                    left: 0;
                    bottom: 0;
                    background-color: #C47D53;
                }
            }
        }
    }

    .form-contato {
        padding: 285px 0 154px 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        isolation: isolate;

        @media(max-width: 991px) {
            padding: 240px 0 84px 0;

            & > .container { padding-inline: 25px; }
        }

        &::after, &::before {
            content: '';
            position: absolute;
            inset: 0;
        }
        &::after {
            background: #28464A;
            opacity: 0.1;
            mix-blend-mode: multiply;
            z-index: -2;
        }

        &::before {
            background: linear-gradient(111.77deg, #1B5053 2.4%, rgba(27, 80, 83, 0) 93.16%);
            z-index: -1;
        }

        .title {
            color: #fff;
            margin-bottom: 42px;
        }

        p {
            text-align: left;
            color: #fff;
            max-width: 333px;
        }

        .redes {
            display: flex;
            gap: 24px;
            margin-top: 63px;

            img {
                transition: all .3s ease;

                &:hover {
                    filter: brightness(0) invert(1);
                }
            }
        }

        form {
            max-width: 602px;
            margin-left: 32px;

            @media(max-width: 991px) {
                margin-left: 0;
                margin-top: 50px;
            }

            input,
            select,
            textarea {
                width: 100%;
                background-color: transparent;
                border: solid 1px #fff;

                font-family: Bicyclette;
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
                color: #fff;

                padding: 16px 20px;
                margin: 10px 0;
            }

            select {
                $bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
                background-image: $bs-form-select-bg-img;
                border-radius: 0;

                &:focus { box-shadow: none; }
                option {
                    color: #7D7D7D;
                    font-family: inherit;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 22px;                
                }
            }

            textarea {
                min-height: 140px;
            }

            input::placeholder,
            select::placeholder,
            textarea::placeholder {
                font-family: Bicyclette;
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
                color: #fff;
            }

            .aceite {
                display: flex;
                align-items: center;
                margin-top: 5px;

                p {
                    font-family: Bicyclette;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #FFFFFFB2;
                    max-width: 100%;

                    a {
                        text-decoration: underline;
                        transition: .3s ease-in;

                        &:hover {
                            transition: .3s ease-in;
                            color: #C47D53;
                        }
                    }
                }


                .checkbox.style-c {
                    display: inline-block;
                    position: relative;
                    padding-left: 30px;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }

                .checkbox.style-c input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }

                .checkbox.style-c input:checked~.checkbox__checkmark {
                    background-color: #C47D53;
                }

                .checkbox.style-c input:checked~.checkbox__checkmark:after {
                    opacity: 1;
                }

                .checkbox.style-c:hover input~.checkbox__checkmark {
                    background-color: transparent;
                }

                .checkbox.style-c:hover input:checked~.checkbox__checkmark {
                    background-color: #C47D53;
                }

                .checkbox.style-c .checkbox__checkmark {
                    position: absolute;
                    top: -9px;
                    left: 0;
                    height: 16px;
                    width: 16px;
                    background-color: transparent;
                    transition: background-color 0.25s ease;
                    border-radius: 0px;
                    border: solid 1px #fff;
                }

                .checkbox.style-c .checkbox__checkmark:after {
                    content: "";
                    position: absolute;
                    left: 5px;
                    top: 1px;
                    width: 5px;
                    height: 10px;
                    border: solid #fff;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                    opacity: 0;
                    transition: opacity 0.25s ease;
                }

                .checkbox.style-c .checkbox__body {
                    color: #fff;
                    line-height: 1.4;
                    font-size: 16px;
                }
            }

            button.button {
                margin-top: 59px;
            }
        }

    }

    .trabalhe-conosco {
        padding: 118px 0 109px 0;
        position: relative;
        overflow: hidden;

        @media(max-width: 991px) {
            padding: 60px 0 0px 0;

            & > .container { padding-inline: 25px; }
            p {
                text-align: left;
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 446px;
            right: -45px;
            width: 582px;
            height: 868px;
            background: url('../img/contact/vetor-logo.webp') center center no-repeat;

            @media(max-width: 991px) {
                width: 198px;
                height: 296px;
                background-size: cover;
                top: 20px;
            }
        }

        .title {
            text-align: center;
            line-height: 35px;
            margin-bottom: 35px;

            @media(max-width: 991px) {
                text-align: left;
            }

            &::after {
                right: 0;
                margin: 0 auto;

                @media(max-width: 991px) {
                    right: inherit;
                    margin: inherit;
                }
            }
        }

        .title:last-child {
            text-align: left;
            margin-bottom: 62px;

            &::after {
                right: inherit;
                margin: inherit;
            }
        }

        .parte2 {
            margin-top: 108px;
        }

        ul li {
            list-style: disc;
            font-family: Bicyclette;
            font-size: 18px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;
            color: #7D7D7D;
            margin-bottom: 37px;
            max-width: 600px;

            &::marker {
                color: #C47D53;
            }

        }
    }

    .candidatar {
        @media (max-width: 991px) {
            & > .container { padding-inline: 25px; }
        }
        .title {
            margin-bottom: 30px;
        }

        p {
            text-align: left;
            max-width: 600px;
        }

        form {
            max-width: 602px;
            margin-left: 32px;

            @media(max-width: 991px) {
                margin-left: 0;
                margin-top: 50px;
                margin-bottom: 50px;
            }

            input,
            select,
            textarea {
                width: 100%;
                background-color: transparent;
                border: solid 1px #C47D53;

                font-family: Bicyclette;
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
                color: #7D7D7D;

                padding: 16px 20px;
                margin: 10px 0;
            }

            select {
                border-radius: 0;

                &:focus { box-shadow: none; }
                option {
                    color: #7D7D7D;
                    font-family: inherit;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 22px;                
                }                
            }

            textarea {
                min-height: 140px;
            }

            input::placeholder,
            select::placeholder,
            textarea::placeholder {
                font-family: Bicyclette;
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
                color: #7D7D7D;
            }

            .aceite {
                display: flex;
                align-items: center;
                margin-top: 5px;

                p {
                    font-family: Bicyclette;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #FFFFFFB2;
                    max-width: 100%;

                    a {
                        text-decoration: underline;
                        transition: .3s ease-in;

                        &:hover {
                            transition: .3s ease-in;
                            color: #C47D53;
                        }
                    }
                }


                .checkbox.style-c {
                    display: inline-block;
                    position: relative;
                    padding-left: 30px;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }

                .checkbox.style-c input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }

                .checkbox.style-c input:checked~.checkbox__checkmark {
                    background-color: #C47D53;
                }

                .checkbox.style-c input:checked~.checkbox__checkmark:after {
                    opacity: 1;
                }

                .checkbox.style-c:hover input~.checkbox__checkmark {
                    background-color: transparent;
                }

                .checkbox.style-c:hover input:checked~.checkbox__checkmark {
                    background-color: #C47D53;
                }

                .checkbox.style-c .checkbox__checkmark {
                    position: absolute;
                    top: -9px;
                    left: 0;
                    height: 16px;
                    width: 16px;
                    background-color: transparent;
                    transition: background-color 0.25s ease;
                    border-radius: 0px;
                    border: solid 1px #fff;
                }

                .checkbox.style-c .checkbox__checkmark:after {
                    content: "";
                    position: absolute;
                    left: 7px;
                    top: 2px;
                    width: 5px;
                    height: 10px;
                    border: solid #fff;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                    opacity: 0;
                    transition: opacity 0.25s ease;
                }

                .checkbox.style-c .checkbox__body {
                    color: #fff;
                    line-height: 1.4;
                    font-size: 16px;
                }
            }

            label {
                font-family: Bicyclette;
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
                color: #7D7D7D;
            }

            button.button {
                margin-top: 59px;
            }
        }

        .file-input__input {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }

        .file-input__label {
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 0px;
            width: 100%;
            background-color: transparent;
            border: solid 1px #C47D53;

            font-family: Bicyclette;
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            color: #7D7D7D;

            padding: 16px 20px;
            margin: 10px 0;
        }

        .file-input__label svg {
            height: 16px;
            margin-right: 4px;
        }
    }

    .join {
        margin: 114px 184px 0px 0;
        height: 793px;
        position: relative;

        @media(max-width: 1100px) {
            margin: 0 !important;
            height: 752px;

            .bg-img { height: 509px; }
        }

        &::after {
            content: '';
            position: absolute;
            inset: 0;
            background: linear-gradient(90deg, #172223 4.3%, rgba(23, 34, 35, 0) 88.56%);
            z-index: -1;

            @media (max-width: 1100px) {
                inset: 0 0 243px;
            }
        }

        p {
            text-align: left;
        }

        &--floating {
            background-color: #fff;
            padding: 96px 130px;

            position: absolute;
            top: 91px;
            right: 0;
            z-index: 0;
            width: Min(67vw, 1099px);
            translate: 184px -1px; // margin value

            @media (max-width: 1100px) {
                padding: 60px 25px 61px 43px;
                width: Min(94vw, 400px);
                translate: 0 0;
    
                top: auto;
                bottom: 0;
            }

            &-wrapper {
                max-width: 400px;

                .title {
                    margin-bottom: 60px;
                }

                .text {
                    color: #7D7D7D;
                    text-align: left;
                }

                .button {
                    margin-top: 60px;
                }
            }
        }
    }
}
