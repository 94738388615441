//LIBS
@import 'libs/animations.scss';

// DEFAULT
@import 'general/reset';
@import 'general/media-screen';
@import 'general/variable';
@import 'general/color';
@import 'general/fonts';
@import 'general/mixins';
@import 'general/common';

// LAYOUTS
@import 'layout/header';
@import 'layout/footer';

// COMPONENTS
@import 'components/buttons.scss';
@import 'components/Modal/zoom_modal.scss';
@import 'components/Modal/forms/error_message.scss';
@import 'components/Modal/forms/sucess_message.scss';
@import 'components/Modal/forms/loading.scss';
@import 'components/cookies.scss';

// PAGES
@import 'pages/page404';
@import 'pages/home';
@import 'pages/atuation';
@import 'pages/atuation-detail';
@import 'pages/about';
@import 'pages/team-detail';
@import 'pages/team';
@import 'pages/content-list';
@import 'pages/news-detail';
@import 'pages/article-detail';
@import 'pages/event-detail';
@import 'pages/units';
@import 'pages/contact';
@import 'pages/policy';

//sass --watch resources/site/assets/sass/main.scss:public/site/css/main.css

@font-face {
    font-family: 'Bicyclette';
    src: url('../fonts/Bicyclette-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Bicyclette';
    src: url('../fonts/Bicyclette-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Bicyclette';
    src: url('../fonts/Bicyclette-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Bicyclette';
    src: url('../fonts/Bicyclette-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Bicyclette';
    src: url('../fonts/Bicyclette-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

* { font-family: 'Bicyclette', sans-serif; }
body { font-family: inherit; }

.title {
    font-size: 45px;
    font-weight: 300;
    line-height: 40px;

    position: relative;
    padding-top: 49px;

    @media (max-width: 1100px) {
        font-size: 30px;
        line-height: 1;
        padding-top: 30px;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-color: #C47D53;
        width: 66px;
        height: 3.9px;
    }
    strong {
        color: inherit;
        font-weight: 700;
        line-height: inherit;
    }
}
.t-dark {
    &, & strong { color: #172223; }
}
.t-light {
    &, & strong { color: #fff; }
}
p, .text {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;

    @media (max-width: 1100px) {
        font-size: 16px;
        line-height: 22px;
    }
}
.text + .text {
    margin-top: 25px;

    @media (max-width: 1100px) {
        margin-top: 20px;
    }
}
.bg-img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
}
a { text-decoration: none; }
ul { margin-bottom: 0; }
.swiper {
    width: 100%;
    height: 100%;

    &-pagination {
        &-bullet {
            border-radius: 0;
            border: 1px solid #fff;
            background: rgba(0, 0, 0, 0);
            width: 13.96px;
            height: 13.96px;
            opacity: 1;

            &-active { background: #C47D53; }
        }
    }
}
