#units {
    #bannerHero {
        overflow: hidden;
        padding-top: 0;

        @media (max-width: 1480px) {
            .socials {
                left: auto;
                right: 85px;
            }
        }
        @media (max-width: 1100px) {
            .socials {
                left: 25px !important;
                right: auto !important;
            }
        }
        .text-container {
            padding-top: 160px;
            position: absolute;
            z-index: 999;

            @media(max-width: 991px) {
                padding-top: 50px;
                padding-right: 25px;
            }
            .title {
                max-width: 387px;
            }
        }

        .socials {
            z-index: 3;
        }

        &::before,
        &::after {
            display: none;
        }

        .img-banner {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
        }

        .after {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 2;
            mix-blend-mode: multiply;
            background: linear-gradient(90deg, rgba(27, 80, 83, 0.8) 3.28%, rgba(27, 80, 83, 0.8) 51.12%, rgba(27, 80, 83, 0) 84.69%) !important;
        }

        .before {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 3;
            mix-blend-mode: multiply;
            background: linear-gradient(90deg, #1B5053 21.73%, rgba(27, 80, 83, 0) 54.81%) !important;
        }

        .container {
            position: relative;
            z-index: 99;
        }

        .breadcumbs {
            position: absolute;
            bottom: 0;
        }

        .swiper {
            &-pagination {
                right: 96px;
                top: 232px;

                @media (max-width: 1480px) {
                    right: 39px;
                }
                @media(max-width: 991px) {
                    top: inherit;
                    bottom: 0;
                }                
            }
        }
    }

    .unit {
        padding: 189px 0 0 0;
        overflow: hidden;

        @media(max-width: 991px) {
            padding: 30px 0 0 0;
        }

        &.left .row {
            flex-direction: row-reverse;

            @media(max-width: 991px) {
                flex-direction: row;
            }
        }

        .title {
            margin-bottom: 62px;

            @media(max-width: 991px) {
                margin-bottom: 30px;
            }
        }

        p {
            font-family: Bicyclette;
            font-size: 18px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;
            color: #7D7D7D;
        }

        .info {
            margin-top: 50px;

            @media(max-width: 991px) {
                margin-top: 20px;
            }

            h3 {
                font-family: Bicyclette;
                font-size: 18px;
                font-weight: 700;
                line-height: 25px;
                letter-spacing: 0em;
                color: #C47D53;
                margin-bottom: 13px;
            }

        }

        .button.btn-dark {
            margin-top: 52px;
        }

        .unit-slide {
            position: relative;
            height: 857px;
            width: 122%;
            right: -8%;
            top: -47px;

            @media (max-width: 1700px) {
                width: 90%;
                right: 0;
            }

            @media (max-width: 1100px) {
                width: 100% !important;
                margin-top: 100px;
                height: 857px;
            }

            @media (max-width: 991px) {
                height: 339px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .swiper {
                max-width: 100%;
                max-height: 100%;

                &-slide {
                    height: 703px;
                }
                &-pagination {
                    right: 2.292vw;
                    left: inherit;
                    bottom: -33px;
                    top: inherit;
    
                    @media (max-width: 1100px) {
                        right: 20px;
                        left: auto;
                        bottom: 20px;
                        top: auto;

                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        width: fit-content;
                    }                    
                }
            }
        }
    }
    
    .left {
        .unit-slide {
            right: auto;
            left: -24% !important;
        }

        @media (max-width: 1700px) {
            .unit-slide {
                width: 90%;
                left: 0 !important;
            }
        }
    }

    .join {
        margin: 80px 184px 0px 0;
        height: 793px;
        position: relative;

        @media(max-width: 1100px) {
            margin: 0 0px 60px 0;
            height: calc(296px + 467px);

            .bg-img {
                height: 509px;
            }
        }

        &::after {
            content: '';
            position: absolute;
            inset: 0;
            background: linear-gradient(90deg, #172223 4.3%, rgba(23, 34, 35, 0) 88.56%);
            z-index: -1;
            mix-blend-mode: multiply;
            opacity: .6;

            @media (max-width: 1100px) {
                inset: 0 0 254px 0;
            }
        }

        &--floating {
            background-color: #fff;
            padding: 96px 130px;

            position: absolute;
            top: 91px;
            right: 0;
            z-index: 0;
            width: Min(67vw, 1099px);
            translate: 184px -1px; // margin value

            @media(max-width: 1100px) {
                padding: 50px 40px;
                width: Min(94vw, 384px);
                translate: 0 0;
                top: auto;
                bottom: 0;
            }

            &-wrapper {
                max-width: 400px;

                .title {
                    margin-bottom: 60px;
                }

                .text {
                    color: #7D7D7D;
                }

                .button {
                    margin-top: 60px;
                }
            }
        }
    }
}
